import { PlanDataTypeEnum } from 'api/client';

//!-------------------------------------------------------------------------------------------------
//! Do not modify any code below this comment.
//!
//! NOTE:
//! This file is copied from the `dfes-bushfire-client` and should *NOT* be modified:
//! https://bitbucket.org/adapptor/dfes-bushfires-client/src/master/Analytics/analytics.ts
//!
//! Modifying the contents of this file would cause analytics data to be lost
//! or mismatch with what the app side is doing.
//!
//!-------------------------------------------------------------------------------------------------

export namespace ScreenNames {
  export const terms = 'terms';
  export const faq = 'faq';
  export const privacyPolicy = 'privacy_policy';

  export const register = 'register';
  export const login = 'login';

  export const dashboard = 'dashboard';

  export const surveyIntro = 'survey_intro';

  export const choosePlan = 'choose_plan';
  export const planSummary = 'plan_summary';
  /** Intro screen following user's choice to Leave Early */
  export const leaveIntro = 'leave_sure_intro';
  /**
   * Intro screen following user's request for help to decide.
   * Preserve the (slightly awkward) name to keep analytics aggregation simple.
   */
  export const unsureIntro = 'leave_intro';

  export const reminderList = 'reminder_list';
  export const reminderDetail = 'reminder_detail';

  export const settings = 'settings';
}

export namespace EventNames {
  // Survey
  export const startSurvey = 'start_survey';
  export const viewSurveyQuestion = 'view_survey_question';
  export const exitSurvey = 'exit_survey';

  // Plan
  export const createPlan = 'create_plan';
  export const viewPlanStep = 'view_plan_step';
  export const completePlanStep = 'complete_plan_step';
  export const completePlan = 'complete_plan';
  export const dismissPlan = 'dismiss_plan';
  export const switchPlan = 'switch_plan';
  export const getPlanPdf = 'get_pdf';
  export const shareStatusPressed = 'share_status_pressed';
  export const shareStatusPlatform = 'share_status_platform';
  export const shareStatusFailure = 'share_status_failed';
  export const shareStatusCancellation = 'share_status_cancelled';
  export const selectPlan = 'select_plan';
  export const renamePlan = 'rename_plan';

  export const planMigrated = 'plan_migrated';

  // Account
  export const signUp = 'sign_up';
  export const pushNotifications = 'push_notifications';

  // Downloadable resources
  export const downloadResource = 'download_resource';

  // Modals
  export const modalDialogOpened = 'modal_dialog_opened';
}

export namespace EventParams {
  export interface Base {
    uid?: string;
    suburb?: string;
    postcode?: string;
    isAnonymous: boolean;
    isSignedUp: boolean;
    hasCompletePlan: boolean;
  }

  /**
   * Custom survey step IDs. To preserve the ability to aggregate events across changes to survey questions,
   * only add to this list if a new question is introduced, or an existing question is
   * substantially altered. Survey steps coming from a `SurveySpec` will use the supplied `questionId`.
   */
  export enum SurveyStepId {
    LOCATION = 'location',
    HOUSEHOLD = 'household',
    UNKNOWN = 'unknown',
  }

  /**
   * To preserve the ability to aggregate events across changes to plan question order or content,
   * only add to this list if a new question is introduced, or an existing question is
   * substantially altered.
   */
  export enum PlanStepId {
    LOCATION = 'location',
    HOUSEHOLD = 'household',

    /** When will we leave? */
    WHEN_TO_LEAVE = 'when_to_leave',
    /** Where will we go? */
    WHERE_WILL_WE_GO = 'where_will_we_go',
    /** Who will we call? */
    WHO_WILL_WE_CALL = 'who_will_we_call',
    /** What will we take? */
    WHAT_WILL_WE_TAKE = 'what_will_we_take',

    /** What will you do before the fire front arrives? */
    BEFORE_THE_FRONT = 'before_the_front',
    /** What will you do when the fire arrives at your property? */
    AT_THE_FRONT = 'at_the_front',
    /** What will we do after the fire front has passed? */
    AFTER_THE_FRONT = 'after_the_front',
    /** When will we put the plan into action? */
    WHEN_TO_ACT = 'when_to_act',

    /** What is our backup plan? */
    BACKUP = 'backup',
    /** Where will we shelter if we are trapped? */
    SHELTER = 'shelter',
    /** Resources step */
    RESOURCES = 'resources',

    UNKNOWN = 'unknown',
  }

  export interface StartSurvey extends Base {}

  export interface ViewSurveyQuestion extends Base {
    /** `SurveyStepId` for a custom step, or ID supplied by `SurveySpec` */
    id: string;
  }

  export interface ExitSurvey extends Base {
    /** Named question (e.g., location) or ID supplied by survey spec */
    questionId: string;
    answerId: number;
  }

  export interface CreatePlan extends Base {
    planType: PlanDataTypeEnum;
  }

  export interface ViewPlanStep extends Base {
    planType: PlanDataTypeEnum;
    step: number;
    id: PlanStepId;
  }

  export interface CompletePlanStep extends Base {
    planType: PlanDataTypeEnum;
    step: number;
    id: PlanStepId;
  }

  export interface CompletePlan extends Base {}

  export interface DismissPlan extends Base {
    planType: PlanDataTypeEnum;
    step: number;
    id: PlanStepId;
  }

  export interface SwitchPlan extends Base {
    planType: PlanDataTypeEnum;
    step: number;
    id: PlanStepId;
  }

  export type PlanMigrationType = 'migrated' | 'migration-failed';

  export interface PlanMigrated extends Base {
    migrationType: PlanMigrationType;
    stepsToMigrate?: number;
    failedStep?: number;
    fromVersion: number;
    toVersion: number;
  }

  export type ModalDialogType =
    | 'fire_danger_ratings'
    | 'bushfire_warning_levels';

  export interface OpenedModalDialog extends Base {
    dialogType: ModalDialogType;
  }

  export type PlanPdfIntent = 'email' | 'download' | 'print' | 'share';

  export interface GetPlanPdf extends Base {
    intent: PlanPdfIntent;
  }

  export interface ShareStatus extends Base {
    planType: PlanDataTypeEnum | undefined;
  }

  export interface ShareStatusPlatform extends Base {
    platform: string;
  }

  export interface ShareStatusFailed extends ShareStatus {
    error: string;
  }

  export interface ShareStatusCancelled extends ShareStatus {}

  export interface SelectPlan extends Base {}

  export interface RenamePlan extends Base {}

  export interface SignUp extends Base {
    hasPlan: boolean;
  }

  export interface PushNotifications extends Base {
    status: string;
  }

  export interface DownloadResource extends Base {
    title: string;
    tag: string;
  }
}
