// Firebase App (the core Firebase SDK) is always required and *must be listed first!*
import { logger } from '@hatchd/utils';
import firebase from 'firebase/app';
import { isRunningInBrowser } from 'utils/helpers';

// Add the Firebase products to use (AFTER app import!)
require('firebase/analytics');
require('firebase/auth');

async function initFirebaseClient() {
  const config = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  };

  try {
    firebase.initializeApp(config);
    firebase.auth();
    firebase.analytics.isSupported().then((isSupported) => {
      // `measurementId` has to be defined to use analytics!
      if (config.measurementId && isSupported) firebase.analytics();
    });
  } catch (error) {
    logger.error('Firebase initialization error', error.stack);
  }
}

if (isRunningInBrowser() && !firebase.apps.length) {
  initFirebaseClient();
}

export default firebase;
