import { ReactComponent as ClockIcon } from 'assets/icons/icon-clock.svg';
import { ReactComponent as FireIcon } from 'assets/icons/icon-fire.svg';
import { ReactComponent as HelmetIcon } from 'assets/icons/icon-helmet.svg';
import { ReactComponent as HoseIcon } from 'assets/icons/icon-hose.svg';
import { ReactComponent as InspectHouseIcon } from 'assets/icons/icon-inspect-house.svg';
import { ReactComponent as LuggageIcon } from 'assets/icons/icon-luggage.svg';
import { ReactComponent as MapIcon } from 'assets/icons/icon-map.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/icon-person.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/icon-phone.svg';
import { ReactComponent as PinIcon } from 'assets/icons/icon-pin.svg';
import { ReactComponent as PathIcon } from 'assets/icons/icon-plan.svg';
import { PlanStep, PlanSteps } from 'types/base';

const COMMON_STEP: Record<number, PlanStep> = {
  1: { step: 1, label: 'Where is your bushfire plan for?', icon: PinIcon },
  2: { step: 2, label: 'Who do we need to protect?', icon: PersonIcon },
  7: { step: 7, label: 'Our Backup plan', icon: PathIcon },
} as const;

const STAY_PLAN_STEPS: PlanStep[] = [
  { ...COMMON_STEP[1] },
  { ...COMMON_STEP[2] },
  { step: 3, label: 'Before the front', icon: HoseIcon },
  { step: 4, label: 'During the front', icon: FireIcon },
  { step: 5, label: 'After the front', icon: InspectHouseIcon },
  { step: 6, label: 'When to act', icon: HelmetIcon },
  { ...COMMON_STEP[7] },
];

const LEAVE_PLAN_STEPS: PlanStep[] = [
  { ...COMMON_STEP[1] },
  { ...COMMON_STEP[2] },
  { step: 3, label: 'When will we leave?', icon: ClockIcon },
  {
    step: 4,
    label: 'Where will we go and how will we get there?',
    icon: MapIcon,
  },
  { step: 5, label: 'Who will we call?', icon: PhoneIcon },
  { step: 6, label: 'What will we take?', icon: LuggageIcon },
  { ...COMMON_STEP[7] },
];

export const PLAN_STEPS: PlanSteps = {
  leave: LEAVE_PLAN_STEPS,
  stay: STAY_PLAN_STEPS,
};
