import { logger } from '@hatchd/utils';
import * as Sentry from '@sentry/browser';
import { ActionCodeSettings, UsersApi, UsersApiFactory } from 'api/client';
import { useRouter } from 'next/router';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import AnalyticsService from 'services/analytics';
import { AuthError, SerialisedUser } from 'types/auth';
import { FirebaseUserProfile } from 'types/base';
import { settings } from 'utils/config';
import { createUserProfileFromUid } from 'utils/helpers';
import APP_ROUTES from 'utils/routes';
import statusMessages from 'utils/status-messages';

interface AuthContext {
  /** Indicates whether or not there is a user session (anon or registered) */
  isAuthenticated: boolean;
  isRegisteredUser: boolean;
  isAnonymous: boolean;
  isVerified: boolean;
  /** JSON representation of the Firebase.default.User object */
  user: SerialisedUser | null;
  token: string | null;
  loading: boolean;
  error: AuthError | undefined;
  login: (
    email: string,
    password: string,
    redirectUrl?: string
  ) => Promise<firebase.default.User | null>;
  logout: (redirectUrl?: string) => void;
  startAnonymousSession: () => Promise<void>;
  signUp: (
    email: string,
    password: string,
    displayName: string | null,
    allowCommunications: boolean,
    shouldLinkAccount: boolean
  ) => Promise<firebase.default.User | null>;
  resetPassword: (email: string) => Promise<void>;
  verifyPasswordResetCode: (actionCode: string) => Promise<string | null>;
  confirmPasswordReset: (
    actionCode: string,
    newPassword: string
  ) => Promise<string | null>;
  reloadUser: () => void;
}

const AuthContext = createContext<AuthContext>({
  isAuthenticated: false,
  isRegisteredUser: false,
  isAnonymous: true,
  isVerified: false,
  user: null,
  token: null,
  loading: false,
  error: undefined,
  login: () => Promise.resolve(null),
  logout: () => {},
  startAnonymousSession: () => Promise.resolve(),
  signUp: () => Promise.resolve(null),
  resetPassword: () => Promise.resolve(),
  verifyPasswordResetCode: () => Promise.resolve(null),
  confirmPasswordReset: () => Promise.resolve(null),
  reloadUser: () => {},
});

AuthContext.displayName = 'AuthCtx';

const INITIAL_USER_PROFILE: FirebaseUserProfile = {
  firestorePath: '',
  userId: '',
};

export const AuthProvider: FC = ({ children }) => {
  const router = useRouter();
  const [authUser, setAuthUser] = useState<SerialisedUser | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AuthError | undefined>(undefined);
  const [userProfile, setUserProfile] =
    useState<FirebaseUserProfile>(INITIAL_USER_PROFILE);

  const serialiseUser = (user: firebase.default.User): void => {
    const serialisedUser = user.toJSON() as SerialisedUser;
    setAuthUser(serialisedUser);
  };

  const startAnonymousSession = useCallback(async () => {
    const firebase = (await import('services/firebase/client')).default;
    setLoading(true);

    try {
      const result = await firebase.auth().signInAnonymously();
      if (result.user && !userProfile.userId) {
        await createUserProfile(result.user);
        return Promise.resolve();
      } else {
        setError({ code: '400', message: 'Unable to create user profile' });
        logger.error('Unable to create user profile');
      }
    } catch (error) {
      setError(error);
      const { code, message } = error;
      logger.error('Error signing in anonymously', code, message);
      return Promise.reject();
    } finally {
      setLoading(false);
    }
  }, [userProfile.userId]);

  const login: AuthContext['login'] = async (email, password, redirectUrl) => {
    const firebase = (await import('services/firebase/client')).default;
    setLoading(true);

    try {
      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      setError(undefined);

      if (redirectUrl) {
        router.push(redirectUrl);
      }

      return Promise.resolve(result.user);
    } catch (error) {
      // override the default error messages for common errors.
      switch (error?.code) {
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          error.message = 'Email or password incorrect.';
          break;
        case 'auth/invalid-email':
          error.message = 'Invalid email.';
          break;
        case 'auth/user-disabled':
          error.message = 'Account has been disabled.';
          break;
        default:
          break;
      }
      setError(error);
      // Only destroy user if not anonymous
      if (!authUser?.isAnonymous) {
        setAuthUser(null);
      }
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  const logout = async (redirectUrl?: string) => {
    const firebase = (await import('services/firebase/client')).default;
    setLoading(true);

    try {
      if (token) {
        logger.info('Invalidating token...');
        await UsersApiFactory({ apiKey: token }).logout({ token: '' });
      }
    } catch (error) {
      logger.error('Error invalidating token', error);
    }

    try {
      logger.info('Logging out...');
      await firebase.auth().signOut();
    } catch (error) {
      logger.error('Error logging out', error);
      toast.error(statusMessages.auth.logOutError);
    } finally {
      router.push(redirectUrl ?? APP_ROUTES.home);
      setLoading(false);
      setError(undefined);
      handleSignedOutUser();
    }

    // Remove selected plan ID
    localStorage.removeItem(settings.planIdStorageKey);
  };

  const signUp: AuthContext['signUp'] = async (
    email,
    password,
    displayName,
    allowCommunications,
    shouldLinkAccount
  ) => {
    const firebase = (await import('services/firebase/client')).default;
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );

    setLoading(true);
    setError(undefined);
    let linkedCredential;

    // We only want to link accounts when they have a locally created plan in store.
    // Otherwise, don't attempt to link the account.
    if (shouldLinkAccount) {
      try {
        logger.info('Try linking account...');
        linkedCredential = await firebase
          .auth()
          .currentUser?.linkWithCredential(credential);
      } catch (error) {
        logger.error('Account linking error', error);

        // override the default error messages for common errors.
        switch (error?.code) {
          case 'auth/provider-already-linked':
            error.message = 'Account already exists. Try logging in instead.';
            break;
          default:
            break;
        }
        setError(error);
        setLoading(false);
        return Promise.reject(error);
      }
    }

    let result: firebase.default.auth.UserCredential;
    let shouldCreateProfile = true;

    if (linkedCredential) {
      logger.log('Credentials exist. Signing in...', linkedCredential);
      try {
        result = await firebase.auth().signInWithCredential(credential);
        shouldCreateProfile = false;
      } catch (error) {
        logger.error('Error signing in user', error);
        setError(error);
        setLoading(false);
        return Promise.reject(error);
      }
    } else {
      logger.log('New user. Creating account...');
      try {
        result = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password);
      } catch (error) {
        logger.error('Error creating account', error);
        setError(error);
        setLoading(false);
        return Promise.reject(error);
      }
    }

    if (!result.user) {
      setLoading(false);
      return Promise.reject({
        code: '400',
        message: 'Unable to create account. Please try again.',
      });
    }

    if (shouldCreateProfile) {
      try {
        await createUserProfile(result.user);
      } catch (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    }

    handleSignedUpUser(result.user, displayName, allowCommunications);
    setLoading(false);
    return Promise.resolve(result.user);
  };

  const createUserProfile = async (user: firebase.default.User) => {
    const { isAnonymous } = user;

    logger.info(
      `Creating user profile for ${isAnonymous ? 'anonymous' : 'new'} account`
    );

    const token = await user.getIdToken();
    const UserApi = new UsersApi({
      apiKey: token,
    });

    try {
      const { data } = await UserApi.createUser();
      setUserProfile(data);
      logger.log('Firestore path:', data.firestorePath);
      return Promise.resolve(data);
    } catch (error) {
      logger.error('Error creating user profile', error);
      toast.error(statusMessages.auth.userProfileCreateError);
      setError(error);
      return Promise.reject(error);
    }
  };

  /** Update the display name of the user */
  const updateDisplayName = async (
    user: firebase.default.User,
    displayName: string | null
  ) => {
    const firebase = (await import('services/firebase/client')).default;
    try {
      // Update the Firestore User profile
      const idToken = await user.getIdToken();
      const User = new UsersApi({
        apiKey: idToken,
      });

      logger.info('Updating displayName with:', displayName);
      await User.updateUser({
        displayName: displayName ?? undefined,
      });
      // Refresh the user immediately after changing the displayName
      await user.reload();
      // Update our user state with the latest details
      serialiseUser(firebase.auth().currentUser!);
    } catch (error) {
      logger.error('Error updating displayName', error);
      setError(error);
    }
  };

  const updateAllowCommunications = async (
    user: firebase.default.User,
    allowCommunications: boolean
  ) => {
    const idToken = await user.getIdToken();
    const User = new UsersApi({
      apiKey: idToken,
    });

    logger.info('Updating allowCommunications with:', allowCommunications);
    await User.updateUserProfile({ allowCommunications });
  };

  const handleSignedInUser = async (user: firebase.default.User) => {
    setAuthUser(user.toJSON() as SerialisedUser);
    // Store the users JWT which is used to communicate with the API
    const idToken = await user.getIdToken();
    setToken(idToken);
    setUserProfile(createUserProfileFromUid(user.uid));
    AnalyticsService.onUserLoggedIn(user.uid);
  };

  const handleSignedUpUser = async (
    user: firebase.default.User,
    displayName: string | null,
    allowCommunications: boolean
  ) => {
    // Update the users displayName and communication preference in Firestore.
    // Don't fail the sign up process if we cannot set it.
    try {
      await Promise.all([
        updateDisplayName(user, displayName),
        updateAllowCommunications(user, allowCommunications),
      ]);
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          operation: 'signUp',
        },
      });
    }

    // Trigger email verification process
    const { emailVerification: path } = settings.continueUrl;
    const actionCodeSettings: ActionCodeSettings = {
      // The URL the user is taken to when clicking 'continue' after opening
      // the link in the verification prompt email.
      continueUrl: `${process.env.NEXT_PUBLIC_APP_URL}${path}`,
      canHandleCodeInApp: false, // Firebase SDK default
    };

    try {
      logger.info('Sending verification email');
      const idToken = await user.getIdToken();
      const UserApi = new UsersApi({
        apiKey: idToken,
      });
      await UserApi.verifyEmail(actionCodeSettings);
      // await user.sendEmailVerification(actionCodeSettings); // Firebase SDK fallback
    } catch (error) {
      logger.error('Error sending verification email', error);
      setError(error);
    }
  };

  const handleSignedOutUser = () => {
    setAuthUser(null);
    setToken(null);
    setUserProfile(INITIAL_USER_PROFILE);
    AnalyticsService.onUserLoggedOut();
  };

  const resetPassword: AuthContext['resetPassword'] = async (email) => {
    // Trigger password reset process
    const encodedEmail = encodeURIComponent(email);
    const { passwordReset: path } = settings.continueUrl;
    const actionCodeSettings: ActionCodeSettings = {
      // The URL the user is taken to when clicking 'continue'
      // after they have reset their password.
      continueUrl: `${process.env.NEXT_PUBLIC_APP_URL}${path}?email=${encodedEmail}`,
      canHandleCodeInApp: false, // Firebase default
    };

    try {
      const UserApi = new UsersApi();
      await UserApi.resetPassword({ email, settings: actionCodeSettings });
      // await auth.sendPasswordResetEmail(email, actionCodeSettings); // Firebase SDK (fallback)
      return Promise.resolve();
    } catch (error) {
      logger.error('Error sending password reset email', error);
      switch (error?.code) {
        case 'auth/user-not-found':
          error.message =
            'There is no user account corresponding to this email. The account may have been deleted.';
          break;
        default:
          break;
      }
      setError(error);
      return Promise.reject(error);
    }
  };

  const verifyPasswordResetCode: AuthContext['verifyPasswordResetCode'] =
    async (actionCode) => {
      const firebase = (await import('services/firebase/client')).default;
      try {
        // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#verifypasswordresetcode
        const email = await firebase.auth().verifyPasswordResetCode(actionCode);
        return email;
      } catch (error) {
        // Invalid or expired action code...
        logger.error('Error verifying password reset code.', error);
        return Promise.reject({
          message:
            'Your request to reset your password has expired or the link has already been used. Try resetting your password again.',
        });
      }
    };

  const confirmPasswordReset: AuthContext['confirmPasswordReset'] = async (
    actionCode,
    newPassword
  ) => {
    const firebase = (await import('services/firebase/client')).default;
    try {
      // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#confirmpasswordreset
      await firebase.auth().confirmPasswordReset(actionCode, newPassword);
      logger.info('Password reset complete.');
      return Promise.resolve(null);
    } catch (error) {
      logger.error('Error occurred during confirmation.', error);
      return Promise.reject({
        message:
          'Error occurred during confirmation. The code might have expired or the password is too weak.',
      });
    }
  };

  /** Refresh the current user in the Auth Provider state */
  const reloadUser: AuthContext['reloadUser'] = async () => {
    const firebase = (await import('services/firebase/client')).default;
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      try {
        await currentUser.reload();
        serialiseUser(currentUser);
      } catch (error) {
        setError(error);
      }
    } else {
      logger.warn('No current user while trying to reload user.');
    }
  };

  useEffect(() => {
    let unsubscribeAuthListener = () => {};
    let unsubscribeTokenListener = () => {};

    const addListeners = async () => {
      const firebase = (await import('services/firebase/client')).default;

      // listen for auth state changes (triggered in sign in/out)
      unsubscribeAuthListener = firebase
        .auth()
        .onAuthStateChanged((currentUser) => {
          if (currentUser && !authUser) {
            // User is signed in.
            handleSignedInUser(currentUser);

            const { isAnonymous, uid } = currentUser;
            if (isAnonymous) {
              logger.info('Auth state changed. Anon user signed in', uid);
            } else {
              logger.info(
                'Auth state changed. Registered user has signed in',
                uid,
                currentUser.toJSON()
              );
            }
          } else if (!currentUser && authUser) {
            // No user is signed in.
            logger.log('Auth state changed. User logged out.');
            handleSignedOutUser();
          } else {
            logger.log(
              `Auth state changed. Updated user:`,
              currentUser?.toJSON()
            );
          }
          setLoading(false);
        });

      // listen for id token changes (i.e. token refresh events, sign-in/out)
      // ensuring we have the latest token in memory for convenience.
      unsubscribeTokenListener = firebase
        .auth()
        .onIdTokenChanged(async (currentUser) => {
          if (currentUser) {
            const idToken = await currentUser.getIdToken();
            setToken(idToken);
            logger.log('ID Token has changed.');
          }
        });
    };

    addListeners();

    // unsubscribe to listeners when unmounting
    return () => {
      unsubscribeAuthListener();
      unsubscribeTokenListener();
    };
  }, [authUser]);

  // Ensure the current user state is up to date when the auth state changes
  useEffect(() => {
    let unsubscribeAuthListener = () => {};

    const addListeners = async () => {
      const firebase = (await import('services/firebase/client')).default;
      unsubscribeAuthListener = firebase
        .auth()
        .onAuthStateChanged((currentUser) => {
          if (currentUser) {
            handleSignedInUser(currentUser);
          }
        });
    };

    addListeners();

    // unsubscribe to listeners when unmounting
    return () => {
      unsubscribeAuthListener();
    };
  }, []);

  // Unless we have a user (session), we assume the user is anonymous.
  const isAnonymous = authUser ? authUser.isAnonymous : true;
  const isAuthenticated = !!authUser;
  const isRegisteredUser =
    !loading && !isAnonymous && isAuthenticated && !!token;

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAnonymous,
        isVerified: !!authUser?.emailVerified,
        user: authUser,
        token,
        loading,
        error,
        login,
        signUp,
        logout,
        resetPassword,
        startAnonymousSession,
        verifyPasswordResetCode,
        confirmPasswordReset,
        reloadUser,
        isRegisteredUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
