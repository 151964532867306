/// <reference path="./custom.d.ts" />
/**
 * DFES Bushfires Service
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as url from 'url';
import { Configuration } from './configuration';

/**
 * The default base path
 */
const INITIAL_BASE_PATH = `${process.env.NEXT_PUBLIC_API_ENDPOINT}`.replace(
  /\/+$/,
  ''
);

/**
 * The default configuration used by Api factories.
 */
let defaultConfiguration: Configuration = {};

/**
 * Update the default values used when creating a factory.
 */
export function updateDefaultConfiguration(
  configuration: Partial<Configuration>
) {
  defaultConfiguration = {
    ...defaultConfiguration,
    ...configuration,
  };
}

function getConfiguration(
  customConfiguration: Partial<Configuration> | undefined
) {
  return customConfiguration
    ? { ...defaultConfiguration, ...customConfiguration }
    : defaultConfiguration;
}

const DEFAULT_HEADERS = {
  // Request that the response is never cached.
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  Pragma: 'no-cache',
  Expires: '0',
};

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 * Options for configuring HTTP request.
 * @export
 * @interface FetchOptions
 */
export interface FetchOptions extends RequestInit {
  query?: Record<string, string>;
  headers?: Record<string, string>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string;
  options?: FetchOptions;
}

/**
 * Result of fetch that has no data.
 * @export
 * @interface FetchResponse
 */
export interface FetchResponse {
  response: Response;
}

/**
 * A fetch result that has a data payload.
 * @export
 * @interface FetchResult
 */
export interface FetchResult<T> extends FetchResponse {
  data: T;
}

function fetchWithConfiguration(
  args: FetchArgs,
  customConfiguration?: Partial<Configuration>
) {
  const {
    basePath = INITIAL_BASE_PATH,
    timeout,
    onResponse,
    onError,
  } = getConfiguration(customConfiguration);
  const url = basePath + args.url;
  const startTime = new Date();

  const timedOnResponse = (response: Response) => {
    if (onResponse) {
      const durationMs = new Date().getTime() - startTime.getTime();
      onResponse(response, durationMs);
    }
    return response;
  };

  const timedOnError = (error: any) => {
    if (error) {
      // Decorate the error with the request url and timeout flag.
      const isTimeout = error.name === 'AbortError';
      if (isTimeout) {
        error = new Error('Network request failed: Request timed out');
      }
      error.url = url;
      error.timeout = isTimeout;
    }

    if (onError) {
      const durationMs = new Date().getTime() - startTime.getTime();
      onError(error, durationMs);
    }
    throw error;
  };

  if (timeout === undefined) {
    // No timeout
    return fetch(url, args.options).then(timedOnResponse, timedOnError);
  }

  const controller = new AbortController();
  const options = {
    ...args.options,
    signal: controller.signal,
  };
  const timeoutId = setTimeout(() => controller.abort(), timeout);
  return fetch(url, options)
    .then(timedOnResponse)
    .finally(() => clearTimeout(timeoutId))
    .catch((error) => timedOnError(error));
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  constructor(protected customConfiguration?: Partial<Configuration>) {}
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public readonly name = 'RequiredError';
  constructor(
    public field: string,
    msg?: string
  ) {
    super(msg);
  }
}

type HeaderParameters = Record<string, string>;
type QueryParameters = Record<string, string | boolean | number>;

/**
 * An acknowledgement from the user
 * @export
 * @interface Acknowledgement
 */
export interface Acknowledgement {
  /**
   *
   * @type {boolean}
   * @memberof Acknowledgement
   */
  acknowledged: boolean;
}

/**
 * https://firebase.google.com/docs/reference/node/firebase.auth#actioncodesettings
 * @export
 * @interface ActionCodeSettings
 */
export interface ActionCodeSettings {
  /**
   *
   * @type {string}
   * @memberof ActionCodeSettings
   */
  continueUrl: string;
  /**
   *
   * @type {boolean}
   * @memberof ActionCodeSettings
   */
  canHandleCodeInApp: boolean;
  /**
   *
   * @type {string}
   * @memberof ActionCodeSettings
   */
  iOSBundleId?: string;
  /**
   *
   * @type {string}
   * @memberof ActionCodeSettings
   */
  androidPackageName?: string;
  /**
   *
   * @type {string}
   * @memberof ActionCodeSettings
   */
  androidMinimumVersion?: string;
  /**
   *
   * @type {boolean}
   * @memberof ActionCodeSettings
   */
  androidInstallApp?: boolean;
  /**
   *
   * @type {string}
   * @memberof ActionCodeSettings
   */
  dynamicLinkDomain?: string;
}

/**
 * Backup scenarios
 * @export
 * @interface BackupAndShelter
 */
export interface BackupAndShelter {
  /**
   *
   * @type {BackupAndShelterNooneHome}
   * @memberof BackupAndShelter
   */
  nooneHome: BackupAndShelterNooneHome;
  /**
   *
   * @type {BackupAndShelterGuests}
   * @memberof BackupAndShelter
   */
  guests: BackupAndShelterGuests;
  /**
   *
   * @type {BackupAndShelterShelter}
   * @memberof BackupAndShelter
   */
  shelter: BackupAndShelterShelter;
}

/**
 *
 * @export
 * @interface BackupAndShelterGuests
 */
export interface BackupAndShelterGuests {
  /**
   *
   * @type {Array<string>}
   * @memberof BackupAndShelterGuests
   */
  selections: Array<BackupAndShelterGuestsSelectionsEnum>;
  /**
   *
   * @type {FreeText}
   * @memberof BackupAndShelterGuests
   */
  notes?: FreeText;
}

export enum BackupAndShelterGuestsSelectionsEnum {
  ExplainPlan = <any>'explain_plan',
  StayInformed = <any>'stay_informed',
  PlanEvacuation = <any>'plan_evacuation',
  BeContactable = <any>'be_contactable',
  PrepareChildren = <any>'prepare_children',
}

/**
 *
 * @export
 * @interface BackupAndShelterNooneHome
 */
export interface BackupAndShelterNooneHome {
  /**
   *
   * @type {Array<string>}
   * @memberof BackupAndShelterNooneHome
   */
  selections: Array<BackupAndShelterNooneHomeSelectionsEnum>;
  /**
   *
   * @type {FreeText}
   * @memberof BackupAndShelterNooneHome
   */
  notes?: FreeText;
}

export enum BackupAndShelterNooneHomeSelectionsEnum {
  ComeHome = <any>'come_home',
  BackupPerson = <any>'backup_person',
  EvacuationKitReady = <any>'evacuation_kit_ready',
  ChildrenPetsAway = <any>'children_pets_away',
}

/**
 *
 * @export
 * @interface BackupAndShelterShelter
 */
export interface BackupAndShelterShelter {
  /**
   *
   * @type {Array<string>}
   * @memberof BackupAndShelterShelter
   */
  selections: Array<BackupAndShelterShelterSelectionsEnum>;
  /**
   *
   * @type {FreeText}
   * @memberof BackupAndShelterShelter
   */
  notes?: FreeText;
}

export enum BackupAndShelterShelterSelectionsEnum {
  Room = <any>'room',
  Paddock = <any>'paddock',
  Dam = <any>'dam',
  Beach = <any>'beach',
  Oval = <any>'oval',
}

/**
 * Emergency contact for leave early plan
 * @export
 * @interface Contact
 */
export interface Contact {
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  phoneNumber: string;
}

/**
 * Create/update firestore document response
 * @export
 * @interface CreateUpdateDocumentResponse
 */
export interface CreateUpdateDocumentResponse {
  /**
   * Path to Firestore document that was created/updated; DEPRECATED from version 2 (This is an implementation detail that may change.)
   * @type {string}
   * @memberof CreateUpdateDocumentResponse
   */
  firestorePath: string;
  /**
   * The created/updated plan
   * @type {PlanDataWithMetadata}
   * @memberof CreateUpdateDocumentResponse
   */
  planDataWithMetadata: PlanDataWithMetadata;
}

/**
 * User profile created successfully
 * @export
 * @interface CreateUserResponse
 */
export interface CreateUserResponse {
  /**
   * Unique ID for the newly created user
   * @type {string}
   * @memberof CreateUserResponse
   */
  userId: string;
  /**
   * Path to the Firestore document representing the user's profile
   * @type {string}
   * @memberof CreateUserResponse
   */
  firestorePath: string;
}

/**
 * An array of downloadable resources plus some metadata
 * @export
 * @interface DownloadableResources
 */
export interface DownloadableResources {
  /**
   *
   * @type {string}
   * @memberof DownloadableResources
   */
  title: string;
  /**
   *
   * @type {Array<DownloadableResourcesFiles>}
   * @memberof DownloadableResources
   */
  files: Array<DownloadableResourcesFiles>;
}

/**
 *
 * @export
 * @interface DownloadableResourcesFiles
 */
export interface DownloadableResourcesFiles {
  /**
   *
   * @type {string}
   * @memberof DownloadableResourcesFiles
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof DownloadableResourcesFiles
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof DownloadableResourcesFiles
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof DownloadableResourcesFiles
   */
  filename: string;
}

/**
 * Request body for emailing a plan
 * @export
 * @interface EmailRequest
 */
export interface EmailRequest {
  /**
   * The destination email address
   * @type {string}
   * @memberof EmailRequest
   */
  email: string;
  /**
   * UUID for the bushfire plan to send; if undefined, the user's first plan is chosen (version 1 behaviour)
   * @type {string}
   * @memberof EmailRequest
   */
  id?: string;
}

/**
 * Generic error response
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   * Error code
   * @type {number}
   * @memberof ErrorResponse
   */
  code: number;
  /**
   * Error message
   * @type {string}
   * @memberof ErrorResponse
   */
  message: string;
  /**
   * More error info if applicable
   * @type {string}
   * @memberof ErrorResponse
   */
  details?: string;
}

/**
 *
 * @export
 * @interface FirestoreTime
 */
export type FirestoreTime = string;
type _FirestoreTimeOld = {};

/**
 * Free text input. Each array element represents a single free text field.
 * @export
 * @interface FreeText
 */
export interface FreeText {
  /**
   *
   * @type {Array<string>}
   * @memberof FreeText
   */
  text: Array<string>;
}

/**
 * A general server status summary
 * @export
 * @interface HealthCheckResponse
 */
export interface HealthCheckResponse {
  /**
   * Server version and environment
   * @type {string}
   * @memberof HealthCheckResponse
   */
  version: string;
  /**
   * Server status
   * @type {string}
   * @memberof HealthCheckResponse
   */
  status: HealthCheckResponseStatusEnum;
  /**
   *
   * @type {HealthCheckResponseRedis}
   * @memberof HealthCheckResponse
   */
  redis: HealthCheckResponseRedis;
}

export enum HealthCheckResponseStatusEnum {
  Ok = <any>'ok',
  Warning = <any>'warning',
  Error = <any>'error',
  Unknown = <any>'unknown',
}

/**
 * Redis status
 * @export
 * @interface HealthCheckResponseRedis
 */
export interface HealthCheckResponseRedis {
  /**
   *
   * @type {string}
   * @memberof HealthCheckResponseRedis
   */
  status: HealthCheckResponseRedisStatusEnum;
  /**
   *
   * @type {string}
   * @memberof HealthCheckResponseRedis
   */
  detail?: string;
}

export enum HealthCheckResponseRedisStatusEnum {
  Ok = <any>'ok',
  Warning = <any>'warning',
  Error = <any>'error',
  Unknown = <any>'unknown',
}

/**
 * Household members. Each array element of `members` corresponds to a selected household member (e.g. Me, Other adults, Babies and/or children etc).
 * @export
 * @interface Household
 */
export interface Household {
  /**
   *
   * @type {Array<string>}
   * @memberof Household
   */
  members: Array<HouseholdMembersEnum>;
}

export enum HouseholdMembersEnum {
  Me = <any>'me',
  Others = <any>'others',
  Children = <any>'children',
  Assistance = <any>'assistance',
  Pets = <any>'pets',
  Livestock = <any>'livestock',
}

/**
 * Household location information. Postcode and optional address.
 * @export
 * @interface Location
 */
export interface Location {
  /**
   *
   * @type {string}
   * @memberof Location
   */
  postcode: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  suburb: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  address?: string;
}

/**
 * An page from the CMS
 * @export
 * @interface Page
 */
export interface Page {
  /**
   * UUID for page
   * @type {string}
   * @memberof Page
   */
  _id: string;
  /**
   * Revision
   * @type {string}
   * @memberof Page
   */
  _rev: string;
  /**
   * Document type
   * @type {string}
   * @memberof Page
   */
  _type: string;
  /**
   * Creation timestamp
   * @type {string}
   * @memberof Page
   */
  _createdAt: string;
  /**
   * Update timestamp
   * @type {string}
   * @memberof Page
   */
  _updatedAt: string;
  /**
   * Page title
   * @type {string}
   * @memberof Page
   */
  title: string;
  /**
   * Short page description
   * @type {string}
   * @memberof Page
   */
  description: string;
  /**
   * Used for the standalone page URL
   * @type {string}
   * @memberof Page
   */
  slug: string;
  /**
   * HTML body
   * @type {string}
   * @memberof Page
   */
  body?: string;
}

/**
 * Place, consisting of `name` (i.e., location), and routes by which to travel to it.
 * @export
 * @interface Place
 */
export interface Place {
  /**
   *
   * @type {string}
   * @memberof Place
   */
  name?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Place
   */
  routes?: Array<string>;
}

/**
 * User's plan data
 * @export
 * @interface PlanData
 */
export interface PlanData {
  /**
   * Version of plan spec this data was created for.
   * @type {number}
   * @memberof PlanData
   */
  version: number;
  /**
   * Optional name of the plan.
   * @type {string}
   * @memberof PlanData
   */
  name?: string;
  /**
   * Plan type.
   * @type {string}
   * @memberof PlanData
   */
  type: PlanDataTypeEnum;
  /**
   * Whether or not this plan is currently considered complete
   * @type {boolean}
   * @memberof PlanData
   */
  completed: boolean;
  /**
   * Whether or not the user has acknowledged this plan's completion (i.e. seen the completion modal)
   * @type {boolean}
   * @memberof PlanData
   */
  completionAcknowledged: boolean;
  /**
   *
   * @type {Location}
   * @memberof PlanData
   */
  location?: Location;
  /**
   *
   * @type {Household}
   * @memberof PlanData
   */
  household?: Household;
  /**
   * Backup and shelter considerations.
   * @type {BackupAndShelter}
   * @memberof PlanData
   */
  backupAndShelter?: BackupAndShelter;
  /**
   * Acknowledgement that the user has viewed the preparation checklists screen
   * @type {Acknowledgement}
   * @memberof PlanData
   */
  prepChecklists?: Acknowledgement;
  /**
   *
   * @type {PlanDataWhenToLeaveWithSelections}
   * @memberof PlanData
   */
  whenToLeaveWithSelections?: PlanDataWhenToLeaveWithSelections;
  /**
   *
   * @type {PlanDataWhereToGoOptions}
   * @memberof PlanData
   */
  whereToGoOptions?: PlanDataWhereToGoOptions;
  /**
   *
   * @type {PlanDataWhoToCallOptions}
   * @memberof PlanData
   */
  whoToCallOptions?: PlanDataWhoToCallOptions;
  /**
   *
   * @type {PlanDataWhatToTakeAndWhereToStore}
   * @memberof PlanData
   */
  whatToTakeAndWhereToStore?: PlanDataWhatToTakeAndWhereToStore;
  /**
   * Acknowledgement that the user understands the risks involved before the fire front arrives
   * @type {Acknowledgement}
   * @memberof PlanData
   */
  beforeFireFront?: Acknowledgement;
  /**
   * Acknowledgement that the user understands the risks involved during the fire front
   * @type {Acknowledgement}
   * @memberof PlanData
   */
  duringFireFront?: Acknowledgement;
  /**
   * Acknowledgement that the user understands the risks involved after the fire front has passed
   * @type {Acknowledgement}
   * @memberof PlanData
   */
  afterFireFront?: Acknowledgement;
  /**
   * Acknowledgement that the user has viewed the screen detailing when to put their plan into action
   * @type {Acknowledgement}
   * @memberof PlanData
   */
  whenToAct?: Acknowledgement;
  /**
   * DEPRECATED from version 2; Backup plan (both plans).
   * @type {FreeText}
   * @memberof PlanData
   */
  backup?: FreeText;
  /**
   * DEPRECATED from version 2; Where to take shelter (both plans).
   * @type {FreeText}
   * @memberof PlanData
   */
  shelter?: FreeText;
  /**
   * DEPRECATED from version 2; When to leave (leave early only).
   * @type {FreeText}
   * @memberof PlanData
   */
  whenToLeave?: FreeText;
  /**
   * DEPRECATED from version 2; Evacuation location (leave early only).
   * @type {FreeText}
   * @memberof PlanData
   */
  whereToGo?: FreeText;
  /**
   *
   * @type {PlanDataWhoToCall}
   * @memberof PlanData
   */
  whoToCall?: PlanDataWhoToCall;
  /**
   *
   * @type {PlanDataWhatToTake}
   * @memberof PlanData
   */
  whatToTake?: PlanDataWhatToTake;
}

export enum PlanDataTypeEnum {
  Leave = <any>'leave',
  Stay = <any>'stay',
}

/**
 * DEPRECATED from version 2; Editable list of items to take (leave early only).
 * @export
 * @interface PlanDataWhatToTake
 */
export interface PlanDataWhatToTake {
  /**
   *
   * @type {Array<string>}
   * @memberof PlanDataWhatToTake
   */
  items: Array<string>;
}

/**
 * Editable list of items to take and the location they will be stored (leave early only).
 * @export
 * @interface PlanDataWhatToTakeAndWhereToStore
 */
export interface PlanDataWhatToTakeAndWhereToStore {
  /**
   *
   * @type {Array<string>}
   * @memberof PlanDataWhatToTakeAndWhereToStore
   */
  items: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PlanDataWhatToTakeAndWhereToStore
   */
  kitLocation: string;
  /**
   * Acknowledgement that the user understands they need to consider what they will take in the event of a fire and where to store it
   * @type {boolean}
   * @memberof PlanDataWhatToTakeAndWhereToStore
   */
  acknowledged: boolean;
}

/**
 * When to leave selections, and optional notes (leave early only).
 * @export
 * @interface PlanDataWhenToLeaveWithSelections
 */
export interface PlanDataWhenToLeaveWithSelections {
  /**
   *
   * @type {Array<string>}
   * @memberof PlanDataWhenToLeaveWithSelections
   */
  selections: Array<PlanDataWhenToLeaveWithSelectionsSelectionsEnum>;
  /**
   *
   * @type {FreeText}
   * @memberof PlanDataWhenToLeaveWithSelections
   */
  notes?: FreeText;
}

export enum PlanDataWhenToLeaveWithSelectionsSelectionsEnum {
  FireDanger = <any>'fire_danger',
  BushfireAdvice = <any>'bushfire_advice',
  BushfireWatchAndAct = <any>'bushfire_watch_and_act',
  Lightning = <any>'lightning',
  Smoke = <any>'smoke',
  PropertyWillBeAffected = <any>'property_will_be_affected',
}

/**
 * Evacuation locations and possible route(s) of travel (leave early only).
 * @export
 * @interface PlanDataWhereToGoOptions
 */
export interface PlanDataWhereToGoOptions {
  /**
   *
   * @type {Array<Place>}
   * @memberof PlanDataWhereToGoOptions
   */
  places: Array<Place>;
}

/**
 * DEPRECATED from version 2; Emergency contact(s) (leave early only).
 * @export
 * @interface PlanDataWhoToCall
 */
export interface PlanDataWhoToCall {
  /**
   *
   * @type {Contact}
   * @memberof PlanDataWhoToCall
   */
  first: Contact;
  /**
   *
   * @type {Contact}
   * @memberof PlanDataWhoToCall
   */
  second?: Contact;
}

/**
 * Emergency contact(s) (leave early only).
 * @export
 * @interface PlanDataWhoToCallOptions
 */
export interface PlanDataWhoToCallOptions {
  /**
   *
   * @type {Array<Contact>}
   * @memberof PlanDataWhoToCallOptions
   */
  contacts: Array<Contact>;
}

/**
 * User plan data with sever-created metadata
 * @export
 * @interface PlanDataWithMetadata
 */
export interface PlanDataWithMetadata {
  /**
   * The plan's UUID
   * @type {string}
   * @memberof PlanDataWithMetadata
   */
  id: string;
  /**
   * Timestamp of the last update; set only by the server from version 2
   * @type {FirestoreTime}
   * @memberof PlanDataWithMetadata
   */
  updated: FirestoreTime;
  /**
   *
   * @type {PlanData}
   * @memberof PlanDataWithMetadata
   */
  plan: PlanData;
}

/**
 * A map of postcodes to suburbs/DFES regions
 * @export
 * @interface Regions
 */
export interface Regions {
  [key: string]: Array<SuburbRegion>;
}

/**
 * Request body for requesting a password reset email
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordRequest
   */
  email: string;
  /**
   *
   * @type {ActionCodeSettings}
   * @memberof ResetPasswordRequest
   */
  settings?: ActionCodeSettings;
}

/**
 * A map of resource tag slugs to downloadable resources
 * @export
 * @interface ResourceMap
 */
export interface ResourceMap {
  [key: string]: DownloadableResources;
}

/**
 * The name of a suburb and its DFES region
 * @export
 * @interface SuburbRegion
 */
export interface SuburbRegion {
  /**
   *
   * @type {string}
   * @memberof SuburbRegion
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SuburbRegion
   */
  region: string;
}

/**
 * A possible answer for a survey question
 * @export
 * @interface SurveyAnswer
 */
export interface SurveyAnswer {
  /**
   *
   * @type {number}
   * @memberof SurveyAnswer
   */
  answerId: number;
  /**
   *
   * @type {string}
   * @memberof SurveyAnswer
   */
  label: string;
  /**
   *
   * @type {number}
   * @memberof SurveyAnswer
   */
  tooltipId?: number;
  /**
   *
   * @type {string}
   * @memberof SurveyAnswer
   */
  exit?: SurveyAnswerExitEnum;
}

export enum SurveyAnswerExitEnum {
  Leave = <any>'leave',
  Stay = <any>'stay',
}

/**
 * Assessment tool responses
 * @export
 * @interface SurveyData
 */
export interface SurveyData {
  /**
   *
   * @type {number}
   * @memberof SurveyData
   */
  version: number;
  /**
   *
   * @type {Location}
   * @memberof SurveyData
   */
  location: Location;
  /**
   *
   * @type {Household}
   * @memberof SurveyData
   */
  household: Household;
  /**
   * Answers array. Each element represents an answer to a question in the assessment tool.
   * @type {Array<number>}
   * @memberof SurveyData
   */
  answers: Array<number>;
}

/**
 * A question in the assessment tool
 * @export
 * @interface SurveyQuestion
 */
export interface SurveyQuestion {
  /**
   *
   * @type {number}
   * @memberof SurveyQuestion
   */
  questionId: number;
  /**
   *
   * @type {string}
   * @memberof SurveyQuestion
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof SurveyQuestion
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof SurveyQuestion
   */
  description?: string;
  /**
   *
   * @type {Array<SurveyAnswer>}
   * @memberof SurveyQuestion
   */
  answers: Array<SurveyAnswer>;
}

/**
 * Specifies the questions, answers and tooltips for the assessment tool
 * @export
 * @interface SurveySpec
 */
export interface SurveySpec {
  /**
   *
   * @type {number}
   * @memberof SurveySpec
   */
  version: number;
  /**
   *
   * @type {Array<SurveyQuestion>}
   * @memberof SurveySpec
   */
  questions: Array<SurveyQuestion>;
  /**
   *
   * @type {Array<SurveyTooltip>}
   * @memberof SurveySpec
   */
  tooltips: Array<SurveyTooltip>;
}

/**
 * A tooltip that can be displayed if a particular answer is selected
 * @export
 * @interface SurveyTooltip
 */
export interface SurveyTooltip {
  /**
   *
   * @type {number}
   * @memberof SurveyTooltip
   */
  tooltipId: number;
  /**
   *
   * @type {string}
   * @memberof SurveyTooltip
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof SurveyTooltip
   */
  description?: string;
}

/**
 * Request body for updating a push token
 * @export
 * @interface TokenRequest
 */
export interface TokenRequest {
  /**
   * The user's Firebase Cloud Messaging token
   * @type {string}
   * @memberof TokenRequest
   */
  token: string;
}

/**
 * Specify one or more fields to update.
 * @export
 * @interface UpdateUserProfileRequest
 */
export interface UpdateUserProfileRequest {
  /**
   * Whether or not to allow non-transactional communications from DFES
   * @type {boolean}
   * @memberof UpdateUserProfileRequest
   */
  allowCommunications?: boolean;
  /**
   * The latest version of the client acknowledged by the user
   * @type {number}
   * @memberof UpdateUserProfileRequest
   */
  latestAcknowledgedVersion?: number;
}

/**
 * Specify one or more fields to update.
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
  /**
   * Firebase authentication display name
   * @type {string}
   * @memberof UpdateUserRequest
   */
  displayName?: string;
  /**
   * Email address
   * @type {string}
   * @memberof UpdateUserRequest
   */
  email?: string;
  /**
   * Account password
   * @type {string}
   * @memberof UpdateUserRequest
   */
  password?: string;
  /**
   * Phone number
   * @type {string}
   * @memberof UpdateUserRequest
   */
  phoneNumber?: string;
  /**
   * URL to a photo to be used as the user's avatar
   * @type {string}
   * @memberof UpdateUserRequest
   */
  photoUrl?: string;
}

/**
 * User profile information
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
  /**
   * Whether or not to allow non-transactional communications from DFES
   * @type {boolean}
   * @memberof UserProfile
   */
  allowCommunications: boolean;
  /**
   * The latest version of the client acknowledged by the user
   * @type {number}
   * @memberof UserProfile
   */
  latestAcknowledgedVersion?: number;
}

/**
 * CmsApi - fetch parameter creator
 * @export
 */
export const CmsApiFetchParamCreator = function (
  customConfiguration?: Partial<Configuration>
) {
  return {
    /**
     * Gets the CMS page containing Disclaimer.
     * @summary Get the CMS page containing the Disclaimer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDisclaimer(options: FetchOptions = {}): FetchArgs {
      const localVarPath = `/cms/disclaimer`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      localVarHeaderParameter['Accept'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets the CMS page containing Disclaimer.
     * @summary Get the CMS page containing the Disclaimer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDisclaimerDeprecated(options: FetchOptions = {}): FetchArgs {
      const localVarPath = `/terms`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      localVarHeaderParameter['Accept'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets a map of resource tag slugs to download resources to be saved client side.
     * @summary Get a map of resource tag slugs to download resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadResources(options: FetchOptions = {}): FetchArgs {
      const localVarPath = `/cms/resources`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      localVarHeaderParameter['Accept'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets a map of resource tag slugs to download resources to be saved client side.
     * @summary Get a map of resource tag slugs to download resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadResourcesDeprecated(options: FetchOptions = {}): FetchArgs {
      const localVarPath = `/resources`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      localVarHeaderParameter['Accept'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets the CMS page containing FAQs.
     * @summary Get the CMS page containing FAQs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFAQ(options: FetchOptions = {}): FetchArgs {
      const localVarPath = `/cms/faq`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      localVarHeaderParameter['Accept'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets the CMS page containing FAQs.
     * @summary Get the CMS page containing FAQs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFAQDeprecated(options: FetchOptions = {}): FetchArgs {
      const localVarPath = `/faq`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      localVarHeaderParameter['Accept'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets the CMS page containing the Privacy Policy.
     * @summary Get the CMS page containing the Privacy Policy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrivacyPolicy(options: FetchOptions = {}): FetchArgs {
      const localVarPath = `/cms/privacy_policy`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      localVarHeaderParameter['Accept'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CmsApi - functional programming interface
 * @export
 */
export const CmsApiFp = function (
  customConfiguration?: Partial<Configuration>
) {
  return {
    /**
     * Gets the CMS page containing Disclaimer.
     * @summary Get the CMS page containing the Disclaimer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDisclaimer(options?: FetchOptions): () => Promise<FetchResult<Page>> {
      const localVarFetchArgs =
        CmsApiFetchParamCreator(customConfiguration).getDisclaimer(options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json().then((data) => ({ response, data }));
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Gets the CMS page containing Disclaimer.
     * @summary Get the CMS page containing the Disclaimer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDisclaimerDeprecated(
      options?: FetchOptions
    ): () => Promise<FetchResult<Page>> {
      const localVarFetchArgs =
        CmsApiFetchParamCreator(customConfiguration).getDisclaimerDeprecated(
          options
        );
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json().then((data) => ({ response, data }));
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Gets a map of resource tag slugs to download resources to be saved client side.
     * @summary Get a map of resource tag slugs to download resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadResources(
      options?: FetchOptions
    ): () => Promise<FetchResult<ResourceMap>> {
      const localVarFetchArgs =
        CmsApiFetchParamCreator(customConfiguration).getDownloadResources(
          options
        );
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json().then((data) => ({ response, data }));
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Gets a map of resource tag slugs to download resources to be saved client side.
     * @summary Get a map of resource tag slugs to download resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadResourcesDeprecated(
      options?: FetchOptions
    ): () => Promise<FetchResult<ResourceMap>> {
      const localVarFetchArgs =
        CmsApiFetchParamCreator(
          customConfiguration
        ).getDownloadResourcesDeprecated(options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json().then((data) => ({ response, data }));
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Gets the CMS page containing FAQs.
     * @summary Get the CMS page containing FAQs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFAQ(options?: FetchOptions): () => Promise<FetchResult<Page>> {
      const localVarFetchArgs =
        CmsApiFetchParamCreator(customConfiguration).getFAQ(options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json().then((data) => ({ response, data }));
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Gets the CMS page containing FAQs.
     * @summary Get the CMS page containing FAQs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFAQDeprecated(options?: FetchOptions): () => Promise<FetchResult<Page>> {
      const localVarFetchArgs =
        CmsApiFetchParamCreator(customConfiguration).getFAQDeprecated(options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json().then((data) => ({ response, data }));
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Gets the CMS page containing the Privacy Policy.
     * @summary Get the CMS page containing the Privacy Policy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrivacyPolicy(options?: FetchOptions): () => Promise<FetchResult<Page>> {
      const localVarFetchArgs =
        CmsApiFetchParamCreator(customConfiguration).getPrivacyPolicy(options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json().then((data) => ({ response, data }));
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * CmsApi - factory interface
 * @export
 */
export const CmsApiFactory = function (
  customConfiguration?: Partial<Configuration>
) {
  return {
    /**
     * Gets the CMS page containing Disclaimer.
     * @summary Get the CMS page containing the Disclaimer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDisclaimer(options?: FetchOptions) {
      return CmsApiFp(customConfiguration).getDisclaimer(options)();
    },
    /**
     * Gets the CMS page containing Disclaimer.
     * @summary Get the CMS page containing the Disclaimer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDisclaimerDeprecated(options?: FetchOptions) {
      return CmsApiFp(customConfiguration).getDisclaimerDeprecated(options)();
    },
    /**
     * Gets a map of resource tag slugs to download resources to be saved client side.
     * @summary Get a map of resource tag slugs to download resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadResources(options?: FetchOptions) {
      return CmsApiFp(customConfiguration).getDownloadResources(options)();
    },
    /**
     * Gets a map of resource tag slugs to download resources to be saved client side.
     * @summary Get a map of resource tag slugs to download resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadResourcesDeprecated(options?: FetchOptions) {
      return CmsApiFp(customConfiguration).getDownloadResourcesDeprecated(
        options
      )();
    },
    /**
     * Gets the CMS page containing FAQs.
     * @summary Get the CMS page containing FAQs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFAQ(options?: FetchOptions) {
      return CmsApiFp(customConfiguration).getFAQ(options)();
    },
    /**
     * Gets the CMS page containing FAQs.
     * @summary Get the CMS page containing FAQs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFAQDeprecated(options?: FetchOptions) {
      return CmsApiFp(customConfiguration).getFAQDeprecated(options)();
    },
    /**
     * Gets the CMS page containing the Privacy Policy.
     * @summary Get the CMS page containing the Privacy Policy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrivacyPolicy(options?: FetchOptions) {
      return CmsApiFp(customConfiguration).getPrivacyPolicy(options)();
    },
  };
};

/**
 * CmsApi - object-oriented interface
 * @export
 * @class CmsApi
 * @extends {BaseAPI}
 */
export class CmsApi extends BaseAPI {
  /**
   * Gets the CMS page containing Disclaimer.
   * @summary Get the CMS page containing the Disclaimer
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CmsApi
   */
  public getDisclaimer(options?: FetchOptions) {
    return CmsApiFp(this.customConfiguration).getDisclaimer(options)();
  }

  /**
   * Gets the CMS page containing Disclaimer.
   * @summary Get the CMS page containing the Disclaimer
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CmsApi
   */
  public getDisclaimerDeprecated(options?: FetchOptions) {
    return CmsApiFp(this.customConfiguration).getDisclaimerDeprecated(
      options
    )();
  }

  /**
   * Gets a map of resource tag slugs to download resources to be saved client side.
   * @summary Get a map of resource tag slugs to download resources
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CmsApi
   */
  public getDownloadResources(options?: FetchOptions) {
    return CmsApiFp(this.customConfiguration).getDownloadResources(options)();
  }

  /**
   * Gets a map of resource tag slugs to download resources to be saved client side.
   * @summary Get a map of resource tag slugs to download resources
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CmsApi
   */
  public getDownloadResourcesDeprecated(options?: FetchOptions) {
    return CmsApiFp(this.customConfiguration).getDownloadResourcesDeprecated(
      options
    )();
  }

  /**
   * Gets the CMS page containing FAQs.
   * @summary Get the CMS page containing FAQs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CmsApi
   */
  public getFAQ(options?: FetchOptions) {
    return CmsApiFp(this.customConfiguration).getFAQ(options)();
  }

  /**
   * Gets the CMS page containing FAQs.
   * @summary Get the CMS page containing FAQs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CmsApi
   */
  public getFAQDeprecated(options?: FetchOptions) {
    return CmsApiFp(this.customConfiguration).getFAQDeprecated(options)();
  }

  /**
   * Gets the CMS page containing the Privacy Policy.
   * @summary Get the CMS page containing the Privacy Policy
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CmsApi
   */
  public getPrivacyPolicy(options?: FetchOptions) {
    return CmsApiFp(this.customConfiguration).getPrivacyPolicy(options)();
  }
}

/**
 * GeospatialApi - fetch parameter creator
 * @export
 */
export const GeospatialApiFetchParamCreator = function (
  customConfiguration?: Partial<Configuration>
) {
  return {
    /**
     * Gets an array of suburbs and DFES regions to be saved client side.
     * @summary Get a list of suburbs/DFES regions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRegions(options: FetchOptions = {}): FetchArgs {
      const localVarPath = `/regions`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      localVarHeaderParameter['Accept'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GeospatialApi - functional programming interface
 * @export
 */
export const GeospatialApiFp = function (
  customConfiguration?: Partial<Configuration>
) {
  return {
    /**
     * Gets an array of suburbs and DFES regions to be saved client side.
     * @summary Get a list of suburbs/DFES regions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRegions(options?: FetchOptions): () => Promise<FetchResult<Regions>> {
      const localVarFetchArgs =
        GeospatialApiFetchParamCreator(customConfiguration).getRegions(options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json().then((data) => ({ response, data }));
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * GeospatialApi - factory interface
 * @export
 */
export const GeospatialApiFactory = function (
  customConfiguration?: Partial<Configuration>
) {
  return {
    /**
     * Gets an array of suburbs and DFES regions to be saved client side.
     * @summary Get a list of suburbs/DFES regions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRegions(options?: FetchOptions) {
      return GeospatialApiFp(customConfiguration).getRegions(options)();
    },
  };
};

/**
 * GeospatialApi - object-oriented interface
 * @export
 * @class GeospatialApi
 * @extends {BaseAPI}
 */
export class GeospatialApi extends BaseAPI {
  /**
   * Gets an array of suburbs and DFES regions to be saved client side.
   * @summary Get a list of suburbs/DFES regions
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeospatialApi
   */
  public getRegions(options?: FetchOptions) {
    return GeospatialApiFp(this.customConfiguration).getRegions(options)();
  }
}

/**
 * HealthcheckApi - fetch parameter creator
 * @export
 */
export const HealthcheckApiFetchParamCreator = function (
  customConfiguration?: Partial<Configuration>
) {
  return {
    /**
     * Server status summary
     * @summary Server health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthcheck(options: FetchOptions = {}): FetchArgs {
      const localVarPath = `/healthcheck`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      localVarHeaderParameter['Accept'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HealthcheckApi - functional programming interface
 * @export
 */
export const HealthcheckApiFp = function (
  customConfiguration?: Partial<Configuration>
) {
  return {
    /**
     * Server status summary
     * @summary Server health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthcheck(
      options?: FetchOptions
    ): () => Promise<FetchResult<HealthCheckResponse>> {
      const localVarFetchArgs =
        HealthcheckApiFetchParamCreator(customConfiguration).healthcheck(
          options
        );
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json().then((data) => ({ response, data }));
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * HealthcheckApi - factory interface
 * @export
 */
export const HealthcheckApiFactory = function (
  customConfiguration?: Partial<Configuration>
) {
  return {
    /**
     * Server status summary
     * @summary Server health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthcheck(options?: FetchOptions) {
      return HealthcheckApiFp(customConfiguration).healthcheck(options)();
    },
  };
};

/**
 * HealthcheckApi - object-oriented interface
 * @export
 * @class HealthcheckApi
 * @extends {BaseAPI}
 */
export class HealthcheckApi extends BaseAPI {
  /**
   * Server status summary
   * @summary Server health check
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthcheckApi
   */
  public healthcheck(options?: FetchOptions) {
    return HealthcheckApiFp(this.customConfiguration).healthcheck(options)();
  }
}

/**
 * PlansApi - fetch parameter creator
 * @export
 */
export const PlansApiFetchParamCreator = function (
  customConfiguration?: Partial<Configuration>
) {
  return {
    /**
     * Check if the account registered for the given email address has a saved plan
     * @summary Check plans for email address
     * @param {string} email Email address to check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkPlans(email: string, options: FetchOptions = {}): FetchArgs {
      // verify required parameter 'email' is not null or undefined
      if (email === null || email === undefined) {
        throw new RequiredError(
          'email',
          'Required parameter email was null or undefined when calling checkPlans.'
        );
      }
      const localVarPath = `/plans/check/{email}`.replace(
        `{${'email'}}`,
        encodeURIComponent(String(email))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a document that stores the user's bushfire plan under their profile in Firestore. Generates a UUID for the plan and returns a Firestore path pointing to the newly created document.
     * @summary Create a bushfire plan
     * @param {PlanData} requestBody Save bushfire plan request body
     * @param {number} [Accept_Version] Plan format version. (If not present, version 1 is assumed.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPlan(
      requestBody: PlanData,
      Accept_Version: number | undefined,
      options: FetchOptions = {}
    ): FetchArgs {
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling createPlan.'
        );
      }
      const localVarPath = `/plans`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      const configuration = getConfiguration(customConfiguration);

      // authentication firebaseAuthenticationToken required
      if (configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      if (Accept_Version !== undefined && Accept_Version !== null) {
        localVarHeaderParameter['Accept-Version'] = String(Accept_Version);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarHeaderParameter['Accept'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );
      const needsSerialization =
        <any>'PlanData' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(requestBody || {})
        : (requestBody as any) /* Should be a string */ || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete the user's bushfire plan under the given UUID.
     * @summary Delete bushfire plan
     * @param {string} id UUID for the bushfire plan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePlan(id: string, options: FetchOptions = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling deletePlan.'
        );
      }
      const localVarPath = `/plans/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: 'DELETE' },
        options
      );
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      const configuration = getConfiguration(customConfiguration);

      // authentication firebaseAuthenticationToken required
      if (configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Emails a user's bushfire plan to the given email address.
     * @summary Email a bushfire plan
     * @param {EmailRequest} requestBody Save bushfire plan request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emailPlan(
      requestBody: EmailRequest,
      options: FetchOptions = {}
    ): FetchArgs {
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling emailPlan.'
        );
      }
      const localVarPath = `/plans/email`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      const configuration = getConfiguration(customConfiguration);

      // authentication firebaseAuthenticationToken required
      if (configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );
      const needsSerialization =
        <any>'EmailRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(requestBody || {})
        : (requestBody as any) /* Should be a string */ || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a PDF for the user's plan
     * @summary Get plan PDF
     * @param {string} id UUID for the bushfire plan
     * @param {number} [Accept_Version] Plan format version. (If not present, version 1 is assumed.)
     * @param {'inline' | 'attachment'} [cd] Desired &#x60;Content-Disposition&#x60; header. Default is no header.
     * @param {string} [filename] Desired filename. Default is &#x60;MyBushfirePlan.pdf&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlanPdf(
      id: string,
      Accept_Version: number | undefined,
      cd: 'inline' | 'attachment' | undefined,
      filename: string | undefined,
      options: FetchOptions = {}
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getPlanPdf.'
        );
      }
      const localVarPath = `/plans/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      const configuration = getConfiguration(customConfiguration);

      // authentication firebaseAuthenticationToken required
      if (configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      if (cd !== undefined) {
        localVarQueryParameter['cd'] = cd;
      }

      if (filename !== undefined) {
        localVarQueryParameter['filename'] = filename;
      }

      if (Accept_Version !== undefined && Accept_Version !== null) {
        localVarHeaderParameter['Accept-Version'] = String(Accept_Version);
      }

      localVarHeaderParameter['Accept'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets the user's saved plans. If the user has no plans saved, an empty array is returned.
     * @summary Get the user's plans
     * @param {number} [Accept_Version] The latest plan format version accepted. (If not present, version 1 is assumed.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlans(
      Accept_Version: number | undefined,
      options: FetchOptions = {}
    ): FetchArgs {
      const localVarPath = `/plans`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      const configuration = getConfiguration(customConfiguration);

      // authentication firebaseAuthenticationToken required
      if (configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      if (Accept_Version !== undefined && Accept_Version !== null) {
        localVarHeaderParameter['Accept-Version'] = String(Accept_Version);
      }

      localVarHeaderParameter['Accept'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update (replace) or create a bushfire plan for the current user under the given UUID. Requires the PlanData object to be sent in the request body.
     * @summary Upsert bushfire plan
     * @param {string} id UUID for the bushfire plan
     * @param {PlanData} requestBody Save bushfire plan request body
     * @param {number} [Accept_Version] Plan format version. (If not present, version 1 is assumed.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertPlan(
      id: string,
      requestBody: PlanData,
      Accept_Version: number | undefined,
      options: FetchOptions = {}
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling upsertPlan.'
        );
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling upsertPlan.'
        );
      }
      const localVarPath = `/plans/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      const configuration = getConfiguration(customConfiguration);

      // authentication firebaseAuthenticationToken required
      if (configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      if (Accept_Version !== undefined && Accept_Version !== null) {
        localVarHeaderParameter['Accept-Version'] = String(Accept_Version);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarHeaderParameter['Accept'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );
      const needsSerialization =
        <any>'PlanData' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(requestBody || {})
        : (requestBody as any) /* Should be a string */ || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PlansApi - functional programming interface
 * @export
 */
export const PlansApiFp = function (
  customConfiguration?: Partial<Configuration>
) {
  return {
    /**
     * Check if the account registered for the given email address has a saved plan
     * @summary Check plans for email address
     * @param {string} email Email address to check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkPlans(
      email: string,
      options?: FetchOptions
    ): () => Promise<FetchResponse> {
      const localVarFetchArgs = PlansApiFetchParamCreator(
        customConfiguration
      ).checkPlans(email, options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return { response };
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Create a document that stores the user's bushfire plan under their profile in Firestore. Generates a UUID for the plan and returns a Firestore path pointing to the newly created document.
     * @summary Create a bushfire plan
     * @param {PlanData} requestBody Save bushfire plan request body
     * @param {number} [Accept_Version] Plan format version. (If not present, version 1 is assumed.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPlan(
      requestBody: PlanData,
      Accept_Version: number | undefined,
      options?: FetchOptions
    ): () => Promise<FetchResult<CreateUpdateDocumentResponse>> {
      const localVarFetchArgs = PlansApiFetchParamCreator(
        customConfiguration
      ).createPlan(requestBody, Accept_Version, options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json().then((data) => ({ response, data }));
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Delete the user's bushfire plan under the given UUID.
     * @summary Delete bushfire plan
     * @param {string} id UUID for the bushfire plan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePlan(
      id: string,
      options?: FetchOptions
    ): () => Promise<FetchResponse> {
      const localVarFetchArgs = PlansApiFetchParamCreator(
        customConfiguration
      ).deletePlan(id, options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return { response };
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Emails a user's bushfire plan to the given email address.
     * @summary Email a bushfire plan
     * @param {EmailRequest} requestBody Save bushfire plan request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emailPlan(
      requestBody: EmailRequest,
      options?: FetchOptions
    ): () => Promise<FetchResponse> {
      const localVarFetchArgs = PlansApiFetchParamCreator(
        customConfiguration
      ).emailPlan(requestBody, options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return { response };
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Get a PDF for the user's plan
     * @summary Get plan PDF
     * @param {string} id UUID for the bushfire plan
     * @param {number} [Accept_Version] Plan format version. (If not present, version 1 is assumed.)
     * @param {'inline' | 'attachment'} [cd] Desired &#x60;Content-Disposition&#x60; header. Default is no header.
     * @param {string} [filename] Desired filename. Default is &#x60;MyBushfirePlan.pdf&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlanPdf(
      id: string,
      Accept_Version: number | undefined,
      cd: 'inline' | 'attachment' | undefined,
      filename: string | undefined,
      options?: FetchOptions
    ): () => Promise<FetchResult<string>> {
      const localVarFetchArgs = PlansApiFetchParamCreator(
        customConfiguration
      ).getPlanPdf(id, Accept_Version, cd, filename, options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json().then((data) => ({ response, data }));
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Gets the user's saved plans. If the user has no plans saved, an empty array is returned.
     * @summary Get the user's plans
     * @param {number} [Accept_Version] The latest plan format version accepted. (If not present, version 1 is assumed.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlans(
      Accept_Version: number | undefined,
      options?: FetchOptions
    ): () => Promise<FetchResult<Array<PlanDataWithMetadata>>> {
      const localVarFetchArgs = PlansApiFetchParamCreator(
        customConfiguration
      ).getPlans(Accept_Version, options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json().then((data) => ({ response, data }));
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Update (replace) or create a bushfire plan for the current user under the given UUID. Requires the PlanData object to be sent in the request body.
     * @summary Upsert bushfire plan
     * @param {string} id UUID for the bushfire plan
     * @param {PlanData} requestBody Save bushfire plan request body
     * @param {number} [Accept_Version] Plan format version. (If not present, version 1 is assumed.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertPlan(
      id: string,
      requestBody: PlanData,
      Accept_Version: number | undefined,
      options?: FetchOptions
    ): () => Promise<FetchResult<CreateUpdateDocumentResponse>> {
      const localVarFetchArgs = PlansApiFetchParamCreator(
        customConfiguration
      ).upsertPlan(id, requestBody, Accept_Version, options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json().then((data) => ({ response, data }));
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * PlansApi - factory interface
 * @export
 */
export const PlansApiFactory = function (
  customConfiguration?: Partial<Configuration>
) {
  return {
    /**
     * Check if the account registered for the given email address has a saved plan
     * @summary Check plans for email address
     * @param {string} email Email address to check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkPlans(email: string, options?: FetchOptions) {
      return PlansApiFp(customConfiguration).checkPlans(email, options)();
    },
    /**
     * Create a document that stores the user's bushfire plan under their profile in Firestore. Generates a UUID for the plan and returns a Firestore path pointing to the newly created document.
     * @summary Create a bushfire plan
     * @param {PlanData} requestBody Save bushfire plan request body
     * @param {number} [Accept_Version] Plan format version. (If not present, version 1 is assumed.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPlan(
      requestBody: PlanData,
      Accept_Version: number | undefined,
      options?: FetchOptions
    ) {
      return PlansApiFp(customConfiguration).createPlan(
        requestBody,
        Accept_Version,
        options
      )();
    },
    /**
     * Delete the user's bushfire plan under the given UUID.
     * @summary Delete bushfire plan
     * @param {string} id UUID for the bushfire plan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePlan(id: string, options?: FetchOptions) {
      return PlansApiFp(customConfiguration).deletePlan(id, options)();
    },
    /**
     * Emails a user's bushfire plan to the given email address.
     * @summary Email a bushfire plan
     * @param {EmailRequest} requestBody Save bushfire plan request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emailPlan(requestBody: EmailRequest, options?: FetchOptions) {
      return PlansApiFp(customConfiguration).emailPlan(requestBody, options)();
    },
    /**
     * Get a PDF for the user's plan
     * @summary Get plan PDF
     * @param {string} id UUID for the bushfire plan
     * @param {number} [Accept_Version] Plan format version. (If not present, version 1 is assumed.)
     * @param {'inline' | 'attachment'} [cd] Desired &#x60;Content-Disposition&#x60; header. Default is no header.
     * @param {string} [filename] Desired filename. Default is &#x60;MyBushfirePlan.pdf&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlanPdf(
      id: string,
      Accept_Version: number | undefined,
      cd: 'inline' | 'attachment' | undefined,
      filename: string | undefined,
      options?: FetchOptions
    ) {
      return PlansApiFp(customConfiguration).getPlanPdf(
        id,
        Accept_Version,
        cd,
        filename,
        options
      )();
    },
    /**
     * Gets the user's saved plans. If the user has no plans saved, an empty array is returned.
     * @summary Get the user's plans
     * @param {number} [Accept_Version] The latest plan format version accepted. (If not present, version 1 is assumed.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlans(Accept_Version: number | undefined, options?: FetchOptions) {
      return PlansApiFp(customConfiguration).getPlans(
        Accept_Version,
        options
      )();
    },
    /**
     * Update (replace) or create a bushfire plan for the current user under the given UUID. Requires the PlanData object to be sent in the request body.
     * @summary Upsert bushfire plan
     * @param {string} id UUID for the bushfire plan
     * @param {PlanData} requestBody Save bushfire plan request body
     * @param {number} [Accept_Version] Plan format version. (If not present, version 1 is assumed.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertPlan(
      id: string,
      requestBody: PlanData,
      Accept_Version: number | undefined,
      options?: FetchOptions
    ) {
      return PlansApiFp(customConfiguration).upsertPlan(
        id,
        requestBody,
        Accept_Version,
        options
      )();
    },
  };
};

/**
 * PlansApi - object-oriented interface
 * @export
 * @class PlansApi
 * @extends {BaseAPI}
 */
export class PlansApi extends BaseAPI {
  /**
   * Check if the account registered for the given email address has a saved plan
   * @summary Check plans for email address
   * @param {string} email Email address to check
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public checkPlans(email: string, options?: FetchOptions) {
    return PlansApiFp(this.customConfiguration).checkPlans(email, options)();
  }

  /**
   * Create a document that stores the user's bushfire plan under their profile in Firestore. Generates a UUID for the plan and returns a Firestore path pointing to the newly created document.
   * @summary Create a bushfire plan
   * @param {PlanData} requestBody Save bushfire plan request body
   * @param {number} [Accept_Version] Plan format version. (If not present, version 1 is assumed.)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public createPlan(
    requestBody: PlanData,
    Accept_Version: number | undefined,
    options?: FetchOptions
  ) {
    return PlansApiFp(this.customConfiguration).createPlan(
      requestBody,
      Accept_Version,
      options
    )();
  }

  /**
   * Delete the user's bushfire plan under the given UUID.
   * @summary Delete bushfire plan
   * @param {string} id UUID for the bushfire plan
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public deletePlan(id: string, options?: FetchOptions) {
    return PlansApiFp(this.customConfiguration).deletePlan(id, options)();
  }

  /**
   * Emails a user's bushfire plan to the given email address.
   * @summary Email a bushfire plan
   * @param {EmailRequest} requestBody Save bushfire plan request body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public emailPlan(requestBody: EmailRequest, options?: FetchOptions) {
    return PlansApiFp(this.customConfiguration).emailPlan(
      requestBody,
      options
    )();
  }

  /**
   * Get a PDF for the user's plan
   * @summary Get plan PDF
   * @param {string} id UUID for the bushfire plan
   * @param {number} [Accept_Version] Plan format version. (If not present, version 1 is assumed.)
   * @param {'inline' | 'attachment'} [cd] Desired &#x60;Content-Disposition&#x60; header. Default is no header.
   * @param {string} [filename] Desired filename. Default is &#x60;MyBushfirePlan.pdf&#x60;
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public getPlanPdf(
    id: string,
    Accept_Version: number | undefined,
    cd: 'inline' | 'attachment' | undefined,
    filename: string | undefined,
    options?: FetchOptions
  ) {
    return PlansApiFp(this.customConfiguration).getPlanPdf(
      id,
      Accept_Version,
      cd,
      filename,
      options
    )();
  }

  /**
   * Gets the user's saved plans. If the user has no plans saved, an empty array is returned.
   * @summary Get the user's plans
   * @param {number} [Accept_Version] The latest plan format version accepted. (If not present, version 1 is assumed.)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public getPlans(Accept_Version: number | undefined, options?: FetchOptions) {
    return PlansApiFp(this.customConfiguration).getPlans(
      Accept_Version,
      options
    )();
  }

  /**
   * Update (replace) or create a bushfire plan for the current user under the given UUID. Requires the PlanData object to be sent in the request body.
   * @summary Upsert bushfire plan
   * @param {string} id UUID for the bushfire plan
   * @param {PlanData} requestBody Save bushfire plan request body
   * @param {number} [Accept_Version] Plan format version. (If not present, version 1 is assumed.)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public upsertPlan(
    id: string,
    requestBody: PlanData,
    Accept_Version: number | undefined,
    options?: FetchOptions
  ) {
    return PlansApiFp(this.customConfiguration).upsertPlan(
      id,
      requestBody,
      Accept_Version,
      options
    )();
  }
}

/**
 * SurveysApi - fetch parameter creator
 * @export
 */
export const SurveysApiFetchParamCreator = function (
  customConfiguration?: Partial<Configuration>
) {
  return {
    /**
     * Create a document that stores the user's survey answers under their Firstore profile. Not required for creating a plan, but may be useful for analytics.
     * @summary Create survey answers
     * @param {SurveyData} requestBody Create survey request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSurvey(
      requestBody: SurveyData,
      options: FetchOptions = {}
    ): FetchArgs {
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling createSurvey.'
        );
      }
      const localVarPath = `/surveys`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      const configuration = getConfiguration(customConfiguration);

      // authentication firebaseAuthenticationToken required
      if (configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarHeaderParameter['Accept'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );
      const needsSerialization =
        <any>'SurveyData' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(requestBody || {})
        : (requestBody as any) /* Should be a string */ || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the most recent version of the survey spec
     * @summary Get the latest survey spec
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSurveySpec(options: FetchOptions = {}): FetchArgs {
      const localVarPath = `/surveys`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      localVarHeaderParameter['Accept'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SurveysApi - functional programming interface
 * @export
 */
export const SurveysApiFp = function (
  customConfiguration?: Partial<Configuration>
) {
  return {
    /**
     * Create a document that stores the user's survey answers under their Firstore profile. Not required for creating a plan, but may be useful for analytics.
     * @summary Create survey answers
     * @param {SurveyData} requestBody Create survey request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSurvey(
      requestBody: SurveyData,
      options?: FetchOptions
    ): () => Promise<FetchResult<CreateUpdateDocumentResponse>> {
      const localVarFetchArgs = SurveysApiFetchParamCreator(
        customConfiguration
      ).createSurvey(requestBody, options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json().then((data) => ({ response, data }));
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Get the most recent version of the survey spec
     * @summary Get the latest survey spec
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSurveySpec(
      options?: FetchOptions
    ): () => Promise<FetchResult<SurveySpec>> {
      const localVarFetchArgs =
        SurveysApiFetchParamCreator(customConfiguration).getSurveySpec(options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json().then((data) => ({ response, data }));
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * SurveysApi - factory interface
 * @export
 */
export const SurveysApiFactory = function (
  customConfiguration?: Partial<Configuration>
) {
  return {
    /**
     * Create a document that stores the user's survey answers under their Firstore profile. Not required for creating a plan, but may be useful for analytics.
     * @summary Create survey answers
     * @param {SurveyData} requestBody Create survey request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSurvey(requestBody: SurveyData, options?: FetchOptions) {
      return SurveysApiFp(customConfiguration).createSurvey(
        requestBody,
        options
      )();
    },
    /**
     * Get the most recent version of the survey spec
     * @summary Get the latest survey spec
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSurveySpec(options?: FetchOptions) {
      return SurveysApiFp(customConfiguration).getSurveySpec(options)();
    },
  };
};

/**
 * SurveysApi - object-oriented interface
 * @export
 * @class SurveysApi
 * @extends {BaseAPI}
 */
export class SurveysApi extends BaseAPI {
  /**
   * Create a document that stores the user's survey answers under their Firstore profile. Not required for creating a plan, but may be useful for analytics.
   * @summary Create survey answers
   * @param {SurveyData} requestBody Create survey request body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SurveysApi
   */
  public createSurvey(requestBody: SurveyData, options?: FetchOptions) {
    return SurveysApiFp(this.customConfiguration).createSurvey(
      requestBody,
      options
    )();
  }

  /**
   * Get the most recent version of the survey spec
   * @summary Get the latest survey spec
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SurveysApi
   */
  public getSurveySpec(options?: FetchOptions) {
    return SurveysApiFp(this.customConfiguration).getSurveySpec(options)();
  }
}

/**
 * UsersApi - fetch parameter creator
 * @export
 */
export const UsersApiFetchParamCreator = function (
  customConfiguration?: Partial<Configuration>
) {
  return {
    /**
     * Creates and saves a new user data profile to Firestore if it doesn't already exist
     * @summary Create a new user data profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(options: FetchOptions = {}): FetchArgs {
      const localVarPath = `/users`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      const configuration = getConfiguration(customConfiguration);

      // authentication firebaseAuthenticationToken required
      if (configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Accept'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes the given push token on the server. If the request contains an Authorization header with a Firebase Authentication token, the push token will no longer be mapped to the UID to which the auth token belongs. If no Authorization header is specified, the token will no longer be mapped to any UID.
     * @summary Delete a push token
     * @param {TokenRequest} requestBody Delete push token request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePushToken(
      requestBody: TokenRequest,
      options: FetchOptions = {}
    ): FetchArgs {
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling deletePushToken.'
        );
      }
      const localVarPath = `/users/push_token`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: 'DELETE' },
        options
      );
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );
      const needsSerialization =
        <any>'TokenRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(requestBody || {})
        : (requestBody as any) /* Should be a string */ || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes a user account. Their Firebase authentication account will be deleted immediately, and their user data will be deleted after 14 days.
     * @summary Delete a user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser(options: FetchOptions = {}): FetchArgs {
      const localVarPath = `/users`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: 'DELETE' },
        options
      );
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      const configuration = getConfiguration(customConfiguration);

      // authentication firebaseAuthenticationToken required
      if (configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets a user's profile data. If no profile data exists for the user, a default profile is returned.
     * @summary Get user's profile data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfile(options: FetchOptions = {}): FetchArgs {
      const localVarPath = `/users/profile`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      const configuration = getConfiguration(customConfiguration);

      // authentication firebaseAuthenticationToken required
      if (configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Accept'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Marks the user's auth token as revoked and deletes their push token if provided
     * @summary Log the user out
     * @param {TokenRequest} [requestBody] Logout request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(
      requestBody: TokenRequest | undefined,
      options: FetchOptions = {}
    ): FetchArgs {
      const localVarPath = `/users/logout`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      const configuration = getConfiguration(customConfiguration);

      // authentication firebaseAuthenticationToken required
      if (configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );
      const needsSerialization =
        <any>'TokenRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(requestBody || {})
        : (requestBody as any) /* Should be a string */ || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sends a password reset email to the given email address.
     * @summary Send password reset email
     * @param {ResetPasswordRequest} requestBody Reset password request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword(
      requestBody: ResetPasswordRequest,
      options: FetchOptions = {}
    ): FetchArgs {
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling resetPassword.'
        );
      }
      const localVarPath = `/users/reset_password`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );
      const needsSerialization =
        <any>'ResetPasswordRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(requestBody || {})
        : (requestBody as any) /* Should be a string */ || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Revokes an email address change in the user's Firebase Auth profile
     * @summary Revoke an email change
     * @param {string} code Code received via email that indicates which email change to revoke
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    revokeEmailChange(code: string, options: FetchOptions = {}): FetchArgs {
      // verify required parameter 'code' is not null or undefined
      if (code === null || code === undefined) {
        throw new RequiredError(
          'code',
          'Required parameter code was null or undefined when calling revokeEmailChange.'
        );
      }
      const localVarPath = `/users/revoke_email_change/{code}`.replace(
        `{${'code'}}`,
        encodeURIComponent(String(code))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      localVarHeaderParameter['Accept'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Caches the given push token for the user on the server
     * @summary Update user push token
     * @param {TokenRequest} requestBody Update push token request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePushToken(
      requestBody: TokenRequest,
      options: FetchOptions = {}
    ): FetchArgs {
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling updatePushToken.'
        );
      }
      const localVarPath = `/users/push_token`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      const configuration = getConfiguration(customConfiguration);

      // authentication firebaseAuthenticationToken required
      if (configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );
      const needsSerialization =
        <any>'TokenRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(requestBody || {})
        : (requestBody as any) /* Should be a string */ || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates a user's Firebase account properties. The request body should include one or more fields to specify what should be updated.
     * @summary Update user's Firebase account properties
     * @param {UpdateUserRequest} requestBody Update user profile request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(
      requestBody: UpdateUserRequest,
      options: FetchOptions = {}
    ): FetchArgs {
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling updateUser.'
        );
      }
      const localVarPath = `/users`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      const configuration = getConfiguration(customConfiguration);

      // authentication firebaseAuthenticationToken required
      if (configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );
      const needsSerialization =
        <any>'UpdateUserRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(requestBody || {})
        : (requestBody as any) /* Should be a string */ || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates a user's profile data. The request body should include one or more fields to specify what should be updated.
     * @summary Update user's profile data
     * @param {UpdateUserProfileRequest} requestBody Update user profile request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfile(
      requestBody: UpdateUserProfileRequest,
      options: FetchOptions = {}
    ): FetchArgs {
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling updateUserProfile.'
        );
      }
      const localVarPath = `/users/profile`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      const configuration = getConfiguration(customConfiguration);

      // authentication firebaseAuthenticationToken required
      if (configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );
      const needsSerialization =
        <any>'UpdateUserProfileRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(requestBody || {})
        : (requestBody as any) /* Should be a string */ || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sends a verification email to the user's registered email address.
     * @summary Verify email address
     * @param {ActionCodeSettings} [requestBody] Verify email request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyEmail(
      requestBody: ActionCodeSettings | undefined,
      options: FetchOptions = {}
    ): FetchArgs {
      const localVarPath = `/users/verify_email`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter: HeaderParameters = {};
      const localVarQueryParameter: QueryParameters = {};

      const configuration = getConfiguration(customConfiguration);

      // authentication firebaseAuthenticationToken required
      if (configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        DEFAULT_HEADERS,
        localVarHeaderParameter,
        options.headers
      );
      const needsSerialization =
        <any>'ActionCodeSettings' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(requestBody || {})
        : (requestBody as any) /* Should be a string */ || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (
  customConfiguration?: Partial<Configuration>
) {
  return {
    /**
     * Creates and saves a new user data profile to Firestore if it doesn't already exist
     * @summary Create a new user data profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(
      options?: FetchOptions
    ): () => Promise<FetchResult<CreateUserResponse>> {
      const localVarFetchArgs =
        UsersApiFetchParamCreator(customConfiguration).createUser(options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json().then((data) => ({ response, data }));
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Deletes the given push token on the server. If the request contains an Authorization header with a Firebase Authentication token, the push token will no longer be mapped to the UID to which the auth token belongs. If no Authorization header is specified, the token will no longer be mapped to any UID.
     * @summary Delete a push token
     * @param {TokenRequest} requestBody Delete push token request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePushToken(
      requestBody: TokenRequest,
      options?: FetchOptions
    ): () => Promise<FetchResponse> {
      const localVarFetchArgs = UsersApiFetchParamCreator(
        customConfiguration
      ).deletePushToken(requestBody, options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return { response };
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Deletes a user account. Their Firebase authentication account will be deleted immediately, and their user data will be deleted after 14 days.
     * @summary Delete a user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser(options?: FetchOptions): () => Promise<FetchResponse> {
      const localVarFetchArgs =
        UsersApiFetchParamCreator(customConfiguration).deleteUser(options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return { response };
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Gets a user's profile data. If no profile data exists for the user, a default profile is returned.
     * @summary Get user's profile data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfile(
      options?: FetchOptions
    ): () => Promise<FetchResult<UserProfile>> {
      const localVarFetchArgs =
        UsersApiFetchParamCreator(customConfiguration).getUserProfile(options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json().then((data) => ({ response, data }));
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Marks the user's auth token as revoked and deletes their push token if provided
     * @summary Log the user out
     * @param {TokenRequest} [requestBody] Logout request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(
      requestBody: TokenRequest | undefined,
      options?: FetchOptions
    ): () => Promise<FetchResponse> {
      const localVarFetchArgs = UsersApiFetchParamCreator(
        customConfiguration
      ).logout(requestBody, options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return { response };
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Sends a password reset email to the given email address.
     * @summary Send password reset email
     * @param {ResetPasswordRequest} requestBody Reset password request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword(
      requestBody: ResetPasswordRequest,
      options?: FetchOptions
    ): () => Promise<FetchResponse> {
      const localVarFetchArgs = UsersApiFetchParamCreator(
        customConfiguration
      ).resetPassword(requestBody, options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return { response };
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Revokes an email address change in the user's Firebase Auth profile
     * @summary Revoke an email change
     * @param {string} code Code received via email that indicates which email change to revoke
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    revokeEmailChange(
      code: string,
      options?: FetchOptions
    ): () => Promise<FetchResult<string>> {
      const localVarFetchArgs = UsersApiFetchParamCreator(
        customConfiguration
      ).revokeEmailChange(code, options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json().then((data) => ({ response, data }));
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Caches the given push token for the user on the server
     * @summary Update user push token
     * @param {TokenRequest} requestBody Update push token request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePushToken(
      requestBody: TokenRequest,
      options?: FetchOptions
    ): () => Promise<FetchResponse> {
      const localVarFetchArgs = UsersApiFetchParamCreator(
        customConfiguration
      ).updatePushToken(requestBody, options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return { response };
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Updates a user's Firebase account properties. The request body should include one or more fields to specify what should be updated.
     * @summary Update user's Firebase account properties
     * @param {UpdateUserRequest} requestBody Update user profile request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(
      requestBody: UpdateUserRequest,
      options?: FetchOptions
    ): () => Promise<FetchResponse> {
      const localVarFetchArgs = UsersApiFetchParamCreator(
        customConfiguration
      ).updateUser(requestBody, options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return { response };
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Updates a user's profile data. The request body should include one or more fields to specify what should be updated.
     * @summary Update user's profile data
     * @param {UpdateUserProfileRequest} requestBody Update user profile request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfile(
      requestBody: UpdateUserProfileRequest,
      options?: FetchOptions
    ): () => Promise<FetchResponse> {
      const localVarFetchArgs = UsersApiFetchParamCreator(
        customConfiguration
      ).updateUserProfile(requestBody, options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return { response };
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Sends a verification email to the user's registered email address.
     * @summary Verify email address
     * @param {ActionCodeSettings} [requestBody] Verify email request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyEmail(
      requestBody: ActionCodeSettings | undefined,
      options?: FetchOptions
    ): () => Promise<FetchResponse> {
      const localVarFetchArgs = UsersApiFetchParamCreator(
        customConfiguration
      ).verifyEmail(requestBody, options);
      return () => {
        return fetchWithConfiguration(
          localVarFetchArgs,
          customConfiguration
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return { response };
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  customConfiguration?: Partial<Configuration>
) {
  return {
    /**
     * Creates and saves a new user data profile to Firestore if it doesn't already exist
     * @summary Create a new user data profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(options?: FetchOptions) {
      return UsersApiFp(customConfiguration).createUser(options)();
    },
    /**
     * Deletes the given push token on the server. If the request contains an Authorization header with a Firebase Authentication token, the push token will no longer be mapped to the UID to which the auth token belongs. If no Authorization header is specified, the token will no longer be mapped to any UID.
     * @summary Delete a push token
     * @param {TokenRequest} requestBody Delete push token request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePushToken(requestBody: TokenRequest, options?: FetchOptions) {
      return UsersApiFp(customConfiguration).deletePushToken(
        requestBody,
        options
      )();
    },
    /**
     * Deletes a user account. Their Firebase authentication account will be deleted immediately, and their user data will be deleted after 14 days.
     * @summary Delete a user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser(options?: FetchOptions) {
      return UsersApiFp(customConfiguration).deleteUser(options)();
    },
    /**
     * Gets a user's profile data. If no profile data exists for the user, a default profile is returned.
     * @summary Get user's profile data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfile(options?: FetchOptions) {
      return UsersApiFp(customConfiguration).getUserProfile(options)();
    },
    /**
     * Marks the user's auth token as revoked and deletes their push token if provided
     * @summary Log the user out
     * @param {TokenRequest} [requestBody] Logout request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(requestBody: TokenRequest | undefined, options?: FetchOptions) {
      return UsersApiFp(customConfiguration).logout(requestBody, options)();
    },
    /**
     * Sends a password reset email to the given email address.
     * @summary Send password reset email
     * @param {ResetPasswordRequest} requestBody Reset password request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword(requestBody: ResetPasswordRequest, options?: FetchOptions) {
      return UsersApiFp(customConfiguration).resetPassword(
        requestBody,
        options
      )();
    },
    /**
     * Revokes an email address change in the user's Firebase Auth profile
     * @summary Revoke an email change
     * @param {string} code Code received via email that indicates which email change to revoke
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    revokeEmailChange(code: string, options?: FetchOptions) {
      return UsersApiFp(customConfiguration).revokeEmailChange(code, options)();
    },
    /**
     * Caches the given push token for the user on the server
     * @summary Update user push token
     * @param {TokenRequest} requestBody Update push token request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePushToken(requestBody: TokenRequest, options?: FetchOptions) {
      return UsersApiFp(customConfiguration).updatePushToken(
        requestBody,
        options
      )();
    },
    /**
     * Updates a user's Firebase account properties. The request body should include one or more fields to specify what should be updated.
     * @summary Update user's Firebase account properties
     * @param {UpdateUserRequest} requestBody Update user profile request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(requestBody: UpdateUserRequest, options?: FetchOptions) {
      return UsersApiFp(customConfiguration).updateUser(requestBody, options)();
    },
    /**
     * Updates a user's profile data. The request body should include one or more fields to specify what should be updated.
     * @summary Update user's profile data
     * @param {UpdateUserProfileRequest} requestBody Update user profile request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfile(
      requestBody: UpdateUserProfileRequest,
      options?: FetchOptions
    ) {
      return UsersApiFp(customConfiguration).updateUserProfile(
        requestBody,
        options
      )();
    },
    /**
     * Sends a verification email to the user's registered email address.
     * @summary Verify email address
     * @param {ActionCodeSettings} [requestBody] Verify email request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyEmail(
      requestBody: ActionCodeSettings | undefined,
      options?: FetchOptions
    ) {
      return UsersApiFp(customConfiguration).verifyEmail(
        requestBody,
        options
      )();
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   * Creates and saves a new user data profile to Firestore if it doesn't already exist
   * @summary Create a new user data profile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public createUser(options?: FetchOptions) {
    return UsersApiFp(this.customConfiguration).createUser(options)();
  }

  /**
   * Deletes the given push token on the server. If the request contains an Authorization header with a Firebase Authentication token, the push token will no longer be mapped to the UID to which the auth token belongs. If no Authorization header is specified, the token will no longer be mapped to any UID.
   * @summary Delete a push token
   * @param {TokenRequest} requestBody Delete push token request body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public deletePushToken(requestBody: TokenRequest, options?: FetchOptions) {
    return UsersApiFp(this.customConfiguration).deletePushToken(
      requestBody,
      options
    )();
  }

  /**
   * Deletes a user account. Their Firebase authentication account will be deleted immediately, and their user data will be deleted after 14 days.
   * @summary Delete a user.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public deleteUser(options?: FetchOptions) {
    return UsersApiFp(this.customConfiguration).deleteUser(options)();
  }

  /**
   * Gets a user's profile data. If no profile data exists for the user, a default profile is returned.
   * @summary Get user's profile data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUserProfile(options?: FetchOptions) {
    return UsersApiFp(this.customConfiguration).getUserProfile(options)();
  }

  /**
   * Marks the user's auth token as revoked and deletes their push token if provided
   * @summary Log the user out
   * @param {TokenRequest} [requestBody] Logout request body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public logout(requestBody: TokenRequest | undefined, options?: FetchOptions) {
    return UsersApiFp(this.customConfiguration).logout(requestBody, options)();
  }

  /**
   * Sends a password reset email to the given email address.
   * @summary Send password reset email
   * @param {ResetPasswordRequest} requestBody Reset password request body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public resetPassword(
    requestBody: ResetPasswordRequest,
    options?: FetchOptions
  ) {
    return UsersApiFp(this.customConfiguration).resetPassword(
      requestBody,
      options
    )();
  }

  /**
   * Revokes an email address change in the user's Firebase Auth profile
   * @summary Revoke an email change
   * @param {string} code Code received via email that indicates which email change to revoke
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public revokeEmailChange(code: string, options?: FetchOptions) {
    return UsersApiFp(this.customConfiguration).revokeEmailChange(
      code,
      options
    )();
  }

  /**
   * Caches the given push token for the user on the server
   * @summary Update user push token
   * @param {TokenRequest} requestBody Update push token request body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updatePushToken(requestBody: TokenRequest, options?: FetchOptions) {
    return UsersApiFp(this.customConfiguration).updatePushToken(
      requestBody,
      options
    )();
  }

  /**
   * Updates a user's Firebase account properties. The request body should include one or more fields to specify what should be updated.
   * @summary Update user's Firebase account properties
   * @param {UpdateUserRequest} requestBody Update user profile request body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUser(requestBody: UpdateUserRequest, options?: FetchOptions) {
    return UsersApiFp(this.customConfiguration).updateUser(
      requestBody,
      options
    )();
  }

  /**
   * Updates a user's profile data. The request body should include one or more fields to specify what should be updated.
   * @summary Update user's profile data
   * @param {UpdateUserProfileRequest} requestBody Update user profile request body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUserProfile(
    requestBody: UpdateUserProfileRequest,
    options?: FetchOptions
  ) {
    return UsersApiFp(this.customConfiguration).updateUserProfile(
      requestBody,
      options
    )();
  }

  /**
   * Sends a verification email to the user's registered email address.
   * @summary Verify email address
   * @param {ActionCodeSettings} [requestBody] Verify email request body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public verifyEmail(
    requestBody: ActionCodeSettings | undefined,
    options?: FetchOptions
  ) {
    return UsersApiFp(this.customConfiguration).verifyEmail(
      requestBody,
      options
    )();
  }
}
