import { ReactComponent } from '*.svg';
import firebase from 'firebase';
import { BaseSyntheticEvent, FormEvent } from 'react';

export type IconComponent = typeof ReactComponent;

export enum QuestionModeEnum {
  Plan = 'plan',
  Survey = 'survey',
}

export type PlanStep = {
  step: number;
  label: string;
  icon: IconComponent;
};

export enum LeaveEarlyIntroModeEnum {
  Default = 'default',
  Help = 'help',
}

export type PlanSteps = Record<string, PlanStep[]>;

export enum NavigationDirectionEnum {
  Previous = 'previous',
  Next = 'next',
}

export type HookFormSubmitEvent =
  | BaseSyntheticEvent<any, HTMLFormElement, FormEvent<HTMLFormElement>>
  | undefined;

export type NavigatePlan = (direction: NavigationDirectionEnum) => void;

export interface FirebaseUserProfile {
  firestorePath: string; // Something like `{env}_users/{uid}`
  userId: firebase.User['uid'];
}
