const APP_ROUTES = {
  home: '/',
  leave: {
    index: '/leave-early',
    get plan() {
      return `${this.index}/plan`;
    },
    get summary() {
      return `${this.index}/summary`;
    },
  },
  stay: {
    index: '/stay-and-defend',
    get survey() {
      return `${this.index}/survey`;
    },
    get plan() {
      return `${this.index}/plan`;
    },
    get summary() {
      return `${this.index}/summary`;
    },
  },
  resources: '/emergency-resources',
  australianWarningSystem: '/australian-warning-system',
  fireDangerRatings: '/FireDangerRatings',
  chooseYourPlan: '/choose-your-plan',
  stayInformed: '/stay-informed',
  users: {
    passwordReset: '/users/password-reset',
    signIn: '/users/sign-in',
    signUp: '/users/sign-up',
    settings: '/users/settings',
  },
  account: '/account',
  reminder: '/reminder',
  // The below URLs could change without notice, which will cause 404s.
  cms: {
    privacyPolicy: '/privacy-policy',
  },
} as const;

export default APP_ROUTES;
