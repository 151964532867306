import { logger } from '@hatchd/utils';
import {
  ErrorResponse,
  HouseholdMembersEnum,
  PlanData,
  PlanDataTypeEnum,
  Regions,
} from 'api/client';
import { format } from 'date-fns';
import firebase from 'firebase';
import { ErrorOption } from 'react-hook-form';
import {
  FirebaseUserProfile,
  HookFormSubmitEvent,
  NavigationDirectionEnum,
  PlanStep,
} from 'types/base';
import APP_ROUTES from './routes';

/** Helpers to determine the NODE ENV (Only use the default envs: development & production) */
export function isDev() {
  return process.env.NODE_ENV === 'development';
}

/** Helpers to determine the NODE ENV (Only use the default envs: development & production) */
export function isProd() {
  return process.env.NODE_ENV === 'production';
}

/** Helpers to determine the BUILD ENV (1 per amplify flow: dev, uat, prod) */
export function isDevBuild() {
  return process.env.NEXT_PUBLIC_BUILD_ENV === 'development';
}

/** Helpers to determine the BUILD ENV (1 per amplify flow: dev, uat, prod) */
export function isStagingBuild() {
  return process.env.NEXT_PUBLIC_BUILD_ENV === 'staging';
}

/** Helpers to determine the BUILD ENV (1 per amplify flow: dev, uat, prod) */
export function isProdBuild() {
  return process.env.NEXT_PUBLIC_BUILD_ENV === 'production';
}

// helper to format Household member enums to the correct copy
export function formatHouseholdMembers(who: HouseholdMembersEnum): string {
  switch (who) {
    case HouseholdMembersEnum.Me:
      return 'Me';
    case HouseholdMembersEnum.Others:
      return 'Other adults';
    case HouseholdMembersEnum.Children:
      return 'Babies and children';
    case HouseholdMembersEnum.Assistance:
      return 'People who need assistance';
    case HouseholdMembersEnum.Pets:
      return 'Pets';
    case HouseholdMembersEnum.Livestock:
      return 'Livestock and other animals';
    default:
      return '';
  }
}

export const isRunningInBrowser = () =>
  !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );

export function isErrorResponse(error: any): error is ErrorResponse {
  return (
    !!error &&
    (error as ErrorResponse).message !== undefined &&
    (error as ErrorResponse).code !== undefined
  );
}

/**
 * Method to capitalise a list of strings or enums
 * @param list - The array to capitalise
 * @returns {array} of capitalised (first letters) string
 */
export function capitaliseArray(list: any[]): string[] {
  if (!list) return [];
  const updatedList: string[] = [];
  list.forEach((i) => {
    updatedList.push(
      i.toString().charAt(0).toUpperCase() + i.toString().slice(1)
    );
  });
  return updatedList;
}

/**
 * Method to find all indexes of `value` in `arr`.
 * @param arr - The array to inspect
 * @param value - The value
 * @returns {array} All indexes of the value to find
 */
export function getAllIndexes(
  arr: (string | boolean | number)[],
  value: string | boolean | number
): number[] {
  const indexes = [];

  for (let i = 0; i < arr.length; i++)
    if (arr[i] === value) {
      indexes.push(i);
    }

  return indexes;
}

/**
 * Trigger a PDF file download or open PDF in new tab/window.
 * @param {blob} blob - the PDF
 * @param {boolean} download
 * @author: https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
 */
export function downloadOrOpenPDF(
  blob: Blob,
  filename?: string,
  download = false
) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob], { type: 'application/pdf' });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.target = '_blank';

  if (download) {
    link.download = filename ?? 'file.pdf';
  }

  link.click();

  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}

/**
 * Method to extract the step number from the query string (if provided).
 *
 * @param step
 * @returns {number} step (default: 1)
 */
export function getStepFromQuery(step: string | string[] | undefined): number {
  let stepNumber = 1;

  if (step === undefined || Array.isArray(step)) {
    return stepNumber;
  }

  if (!isNaN(+step) && isFinite(Number(step)) && Number(step) > 0) {
    stepNumber = Number(step);
  }

  return stepNumber;
}

/**
 * Method to determine if a given step of a given plan is completed.
 * Returns `true` if it is considered to be done.
 * Note: 1-based
 */
export function isPlanStepDone(
  step: number,
  plan: Partial<PlanData> | null
): boolean {
  if (plan?.type === PlanDataTypeEnum.Leave) {
    switch (step) {
      case 1:
        return !!plan?.location?.postcode;
      case 2:
        return !!plan?.household?.members && plan.household.members.length > 0;
      case 3:
        return !!plan?.whenToLeaveWithSelections?.selections?.filter(Boolean)
          .length;
      case 4:
        return (
          !!plan?.whereToGoOptions?.places &&
          !!plan?.whereToGoOptions?.places[0].name &&
          !!plan?.whereToGoOptions?.places[0].routes &&
          !!plan?.whereToGoOptions?.places[0].routes[0]
        );
      case 5:
        return (
          !!plan?.whoToCallOptions?.contacts[0].name &&
          !!plan?.whoToCallOptions?.contacts[0].phoneNumber
        );
      case 6:
        return !!plan?.whatToTakeAndWhereToStore?.acknowledged;
      case 7:
        return (
          !!plan?.backupAndShelter?.guests.selections?.length &&
          !!plan?.backupAndShelter?.nooneHome.selections?.length &&
          !!plan?.backupAndShelter?.shelter.selections?.length
        );
      case 8:
        return !!plan?.prepChecklists?.acknowledged;
      default:
        return false;
    }
  } else {
    // Stay and Defend plan
    switch (step) {
      case 1:
        return !!plan?.location?.postcode;
      case 2:
        return !!plan?.household?.members.length;
      case 3:
        return !!plan?.beforeFireFront?.acknowledged;
      case 4:
        return !!plan?.duringFireFront?.acknowledged;
      case 5:
        return !!plan?.afterFireFront?.acknowledged;
      case 6:
        return !!plan?.whenToAct?.acknowledged;
      case 7:
        return (
          !!plan?.backupAndShelter?.guests.selections?.length &&
          !!plan?.backupAndShelter?.nooneHome.selections?.length &&
          !!plan?.backupAndShelter?.shelter.selections?.length
        );
      default:
        return false;
    }
  }
}

export function getPlanStepsForNav(
  plan: Partial<PlanData>,
  numberOfSteps: number
) {
  const steps = new Array(numberOfSteps).fill(0).map((_, index) => index + 1);

  return steps.map((step) => {
    const isComplete = isPlanStepDone(step, plan);

    return {
      number: step,
      isComplete,
    };
  });
}

/**
 * Method that determines if the first 8 steps of a given plan have been completed.
 * Excludes question 9: Preparation Checklists.
 */
export function isPlanCompleted(
  plan: Partial<PlanData>,
  steps: PlanStep[]
): boolean {
  return !steps
    .slice(0, 8)
    .find((planStep) => !isPlanStepDone(planStep.step, plan));
}

export function getPlanLink(type?: PlanDataTypeEnum) {
  if (type === PlanDataTypeEnum.Leave) {
    return APP_ROUTES.leave;
  }

  if (type === PlanDataTypeEnum.Stay) {
    return APP_ROUTES.stay;
  }
}

export function getPlanTitle(type?: PlanDataTypeEnum) {
  let title = 'Plan';

  if (type === PlanDataTypeEnum.Leave) {
    title = 'Leave early plan';
  }

  if (type === PlanDataTypeEnum.Stay) {
    title = 'Stay and defend plan';
  }

  return title;
}

export function getRegionByLocation(
  location: PlanData['location'],
  regions: Regions
) {
  if (location?.postcode && location.suburb) {
    return (
      regions[location.postcode].find(
        (region) => region.name === location.suburb
      )?.region || ''
    );
  } else {
    return '';
  }
}

/**
 * Method that determines which button was used to submit a form
 * and uses its own value to pass to the navigate method.
 * If we can't determine the value of the submitter, we fall back to "Next"
 * as it at least guides the user towards the goal (plan summary).
 * @param navigate Navigation callback
 * @param event SubmitEvent
 */
export function navigateWithDirection(
  navigate?: (direction: NavigationDirectionEnum) => void,
  event?: HookFormSubmitEvent
): void {
  if (!navigate) return;
  let direction = NavigationDirectionEnum.Next; // Default
  let trigger = '';
  // Use the `submitter` to determine which button invoked the form submission.
  // Reference: https://developer.mozilla.org/en-US/docs/Web/API/SubmitEvent/submitter
  const submitter = event?.nativeEvent?.submitter?.value;
  if (submitter) {
    direction = submitter;
    trigger = 'submitter';
  }

  // If the submitter is undefined (due to lack of browser support),
  // determine the direction using the hidden direction input in form controls.
  if (event?.target instanceof HTMLFormElement) {
    const formData = new FormData(event.target);
    direction = formData.get('direction') as NavigationDirectionEnum;
    trigger = 'form';
  }

  if (isDev()) logger.log('Navigate:', direction, `(${trigger})`);
  navigate(direction);
}

export const resolveApiError = async <T>(
  error: Response,
  func: (name: keyof T, error: ErrorOption) => void,
  name: keyof T,
  fallback: string
) => {
  try {
    Promise.resolve(await error.json()).then((error: ErrorResponse) => {
      func(name, {
        type: 'manual',
        message: error.message,
      });
    });
  } catch {
    func(name, {
      type: 'manual',
      message: `${fallback}. Please try again later.`,
    });
  }
};

export function formatReminderDate(startDate: string, endDate: string) {
  const formatDate = (date: string) => format(new Date(date), 'dd MMMM yyyy');
  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
}

/**
 * Method to construct the user profile given the current users UID.
 * @param uid - Firebase User ID
 */
export function createUserProfileFromUid(
  uid: firebase.User['uid']
): FirebaseUserProfile {
  const base = process.env.NEXT_PUBLIC_FIREBASE_PATH_BASE || 'prod';
  logger.info(`Creating ${base} user profile for:`, uid);
  return {
    firestorePath: `${base}_users/${uid}`,
    userId: uid,
  };
}

/**
 * Method to convert a string to "Title Case"
 * @param sentence
 */
export function titleize(sentence: string) {
  if (!sentence.split) return sentence;
  const titleizeWord = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

  const result: string[] = [];
  sentence.split(/[-\s/]/).forEach((w) => {
    result.push(w !== 'and' ? titleizeWord(w) : w);
  });

  return result.join(' ');
}

/**
 * Method to extract URLs from sitemap.xml body text.
 * @param text
 */
export function getUrlsFromXMLString(text: string) {
  const regex = /(<loc>).*?(?=<\/loc>)/g;
  const locations = text.match(regex);
  if (locations) {
    const urls = locations.map((url) => url.split('/').splice(3).join('/'));
    return urls.sort();
  }
  return locations;
}

/**
 * Simple abstraction to scroll the browser window to the top.
 * Usually after a page transition using `next/router`.
 */
export function scrollToTop(): void {
  window.scrollTo(0, 0);
}
