import React, { createContext, FC, useContext } from 'react';
import { SiteSettings } from 'types/sanity';

interface SiteSettingsContext {
  siteSettings: SiteSettings;
}

export const initialSiteSettings: SiteSettings = {
  title: '',
  description: '',
  nav: {
    header: [],
    footer: [],
  },
};

const SettingsContext = createContext<SiteSettingsContext>({
  siteSettings: initialSiteSettings,
});

export const SiteSettingsProvider: FC<SiteSettingsContext> = ({
  siteSettings,
  children,
}) => {
  return (
    <SettingsContext.Provider
      value={{
        siteSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export function useSiteSettings() {
  const context = useContext(SettingsContext);

  if (context === undefined) {
    throw new Error(
      'useSiteSettings must be used within a SiteSettingsProvider.'
    );
  }

  return context;
}
