import { logger } from '@hatchd/utils';
import * as Sentry from '@sentry/browser';
import { isErrorResponse } from './helpers';

/**
 * Set user context for subsequent Sentry events.
 * @param userId Any unique identifier for the current user.
 */
export function onUserLoggedIn(userId: string) {
  Sentry.setUser({ username: userId });
}

/**
 * Clear the user context set with `setUser`.
 */
export function onUserLoggedOut() {
  Sentry.setUser({});
}

/**
 * Gets the "message" from a JSON response, else returns null if it can't be parsed.
 */
export async function getResponseMessage(
  response: Response
): Promise<string | null> {
  try {
    const res = await response.json();
    if (isErrorResponse(res)) {
      return typeof res.message === 'string' ? res.message : null;
    } else {
      logger.error('Response body is not ErrorResponse:', res);
      return null;
    }
  } catch (error) {
    logger.error("Error: Couldn't get message in response:", error);
    return null;
  }
}

/**
 * Capture a non-fatal error as a message event. Parses
 * HTTP responses, or extracts a message field, and falls back to
 * stringifying `error`.
 */
export async function captureNonFatalError(error: any) {
  if (error instanceof Error) {
    Sentry.captureException(error);
  } else if (error instanceof Response) {
    // Log the message in the error response, if any.
    const message = await getResponseMessage(error);
    const detail = message ? ` "${message}"` : '';
    Sentry.captureMessage(`HTTP ${error.status}${detail}: ${error.url}`);
  } else if (typeof error === 'object' && error.message !== undefined) {
    Sentry.captureMessage(error.message);
  } else {
    let json: string | undefined;

    try {
      json = JSON.stringify(error);
    } catch (e) {
      // Unable to stringify.  Possible circular reference.
    }

    Sentry.captureMessage(json || `${error}`);
  }
}
