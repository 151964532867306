import {
  BackupAndShelterGuestsSelectionsEnum,
  BackupAndShelterNooneHomeSelectionsEnum,
  BackupAndShelterShelterSelectionsEnum,
  PlanDataWhenToLeaveWithSelectionsSelectionsEnum,
} from 'api/client';
import { PLAN_VERSION } from 'api/migration/constants';
import APP_ROUTES from './routes';

// prettier-ignore
const webUrls = {
  appleStore: 'https://apps.apple.com/au/app/my-bushfire-plan/id1540914064',
  playStore: 'https://play.google.com/store/apps/details?id=au.gov.wa.dfes.mybushfireplan',
  facebook: 'https://www.facebook.com/dfeswa',
  twitter: 'https://twitter.com/dfes_wa',
  youtube: 'https://www.youtube.com/dfeswesternaustralia',
  instagram: 'https://www.instagram.com/dfes_wa/',
  linkedin: 'https://www.linkedin.com/company/fire-&-emergency-services-authority/'
} as const

export const settings = {
  previewCookieName: '__preview_mode__',
  nameMinLength: 2,
  passwordMinLength: 8,
  continueUrl: {
    passwordReset: APP_ROUTES.users.signIn,
    emailVerification: APP_ROUTES.account,
  },
  url: { ...webUrls },
  surveyVersion: 0, // default to indicate it has not been set
  planVersion: PLAN_VERSION,
  maxNumOfItems: 10,
  planIdStorageKey: 'selectedPlanId',
} as const;

/**
 * Only allow upper- and lower-case letters and at least 1 number.
 * Length can be set in the settings.
 */
export const passwordRegexp = new RegExp(
  `^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d).{${settings.passwordMinLength},}$`
);

export const backgroundImages = {
  hero: [
    require('assets/images/hero-small-v2.jpg'),
    require('assets/images/hero-small-v2.jpg'),
    require('assets/images/hero-large-v2.jpg'),
  ],
  treesBurn: [
    require('assets/images/trees-burn-small.jpg'),
    require('assets/images/trees-burn-small.jpg'),
    require('assets/images/trees-burn-graphic.jpg'),
  ],
};

interface checklistInfo {
  enum:
    | PlanDataWhenToLeaveWithSelectionsSelectionsEnum
    | BackupAndShelterGuestsSelectionsEnum
    | BackupAndShelterNooneHomeSelectionsEnum
    | BackupAndShelterShelterSelectionsEnum;
  label: string;
}

export const checklistDataWhenToLeave: checklistInfo[] = [
  {
    enum: PlanDataWhenToLeaveWithSelectionsSelectionsEnum.FireDanger,
    label: 'The fire danger rating in my area is Extreme or Catastrophic',
  },
  {
    enum: PlanDataWhenToLeaveWithSelectionsSelectionsEnum.BushfireAdvice,
    label: 'There is a bushfire Advice in my area',
  },
  {
    enum: PlanDataWhenToLeaveWithSelectionsSelectionsEnum.BushfireWatchAndAct,
    label: 'There is a bushfire Watch and Act in my area',
  },
  {
    enum: PlanDataWhenToLeaveWithSelectionsSelectionsEnum.Lightning,
    label: 'There is lightning weather forecast in my area',
  },
  {
    enum: PlanDataWhenToLeaveWithSelectionsSelectionsEnum.Smoke,
    label: 'We see or smell smoke close by',
  },
  {
    enum: PlanDataWhenToLeaveWithSelectionsSelectionsEnum.PropertyWillBeAffected,
    label: 'We believe a fire will affect our property in the next 24 hours',
  },
];

export const checklistDataBackupAndShelterGuests: checklistInfo[] = [
  {
    enum: BackupAndShelterGuestsSelectionsEnum.ExplainPlan,
    label:
      'Talk to your guests about your bushfire plan and when everyone will leave.',
  },
  {
    enum: BackupAndShelterGuestsSelectionsEnum.StayInformed,
    label:
      'Stay informed through Emergency WA and monitor fire danger ratings daily.',
  },
  {
    enum: BackupAndShelterGuestsSelectionsEnum.PlanEvacuation,
    label:
      'Consider what is needed to evacuate your guests and household - will you need to take multiple trips?',
  },
  {
    enum: BackupAndShelterGuestsSelectionsEnum.BeContactable,
    label:
      'If the fire danger is Extreme or above, or if there is a bushfire Advice issued, guests need to be contactable.',
  },
  {
    enum: BackupAndShelterGuestsSelectionsEnum.PrepareChildren,
    label:
      'Make sure any children staying with you understand your bushfire plan and know what is expected of them.',
  },
];

export const checklistDataBackupAndShelterNooneHome: checklistInfo[] = [
  {
    enum: BackupAndShelterNooneHomeSelectionsEnum.ComeHome,
    label:
      'Come home during a bushfire Advice to prepare your property or pick up family or pets. Do not wait and see.',
  },
  {
    enum: BackupAndShelterNooneHomeSelectionsEnum.BackupPerson,
    label:
      'Have a backup person who can help, in case you’re not able to get home. Make sure they know what actions they need to take.',
  },
  {
    enum: BackupAndShelterNooneHomeSelectionsEnum.EvacuationKitReady,
    label: 'Have your Emergency Evacuation Kit ready, so it’s easy to collect.',
  },
  {
    enum: BackupAndShelterNooneHomeSelectionsEnum.ChildrenPetsAway,
    label:
      'On days with a fire danger rating of Extreme or Catastrophic you may have your pets and children stay at a friend or family member’s house.',
  },
];

export const checklistDataBackupAndShelterShelter: checklistInfo[] = [
  {
    enum: BackupAndShelterShelterSelectionsEnum.Room,
    label: 'In a room with two exits and water supply',
  },
  {
    enum: BackupAndShelterShelterSelectionsEnum.Paddock,
    label: 'Open cleared paddock',
  },
  {
    enum: BackupAndShelterShelterSelectionsEnum.Dam,
    label: 'Dam',
  },
  {
    enum: BackupAndShelterShelterSelectionsEnum.Beach,
    label: 'Beach',
  },
  {
    enum: BackupAndShelterShelterSelectionsEnum.Oval,
    label: 'Oval',
  },
];

export const emergencyKitItems: string[] = [
  'Important/legal documents',
  'Precious items (jewellery/medals)',
  'Clothing for everyone',
  'First aid kit',
  'Drinking water, food for the trip',
  'Personal hygiene items',
  'Phone, computer, chargers',
];

export const itemsToGrabWhenLeaving: string[] = [
  'Wallet',
  'Medications, life support equipment',
  'Phone and charger',
  'House and car keys',
  'Laptop and charger',
  'Pets and supplies (food, bowls, cage, leash, etc)',
];
