export default {
  plan: {
    saveSuccess: 'Plan saved successfully',
    saveError: 'Error saving plan',
    saveErrorUnauthenticated:
      'Something went wrong, please try refreshing the page',
    updateSuccess: 'Plan updated successfully',
    updateError: 'Error updating plan',
    fetchError: 'Error fetching plan',
    createError: 'Error creating plan',
  },
  settings: {
    nameUpdateSuccess: 'Name updated successfully',
    nameUpdateError: 'Error updating name',
    passwordUpdateSuccess: 'Password updated successfully, logging out...',
    emailUpdateSuccess: 'Email address updated successfully, logging out...',
  },
  reminderList: {
    reminderFetchError: 'Error fetching reminders',
  },
  auth: {
    userProfileCreateError:
      'Error creating user profile, please try refreshing the page',
    logOutError: 'Error logging out, please try refreshing the page',
    userProfileFetchError: 'Error fetching user profile',
    userProfileUpdateError: 'Error updating user profile',
    userProfileUpdateSuccess: 'User profile updated successfully',
  },
};
