import {
  PlanData,
  PlanDataTypeEnum,
  PlanDataWithMetadata,
  SurveyData,
  SurveySpec,
} from 'api/client';
import produce from 'immer';
import { settings } from 'utils/config';
import create, { UseStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from './utils';

interface SurveyState {
  spec?: SurveySpec;
  draft: SurveyData;
}

const INITIAL_SURVEY_STATE: SurveyState = {
  draft: {
    version: settings.surveyVersion,
    location: { postcode: '', suburb: '' },
    household: { members: [] },
    answers: [],
  },
  spec: undefined,
};

interface PlanState {
  /** Reference to plan used to interact with it (save/download/email) */
  planId: string;
  // Currently, a user can only have one plan..., we'll support multiple plans in the future.
  plans: PlanDataWithMetadata[];
  isDirty: boolean;
  /* Flag to indicate when the plan was last saved by the user */
  updated: string;
  draft: PlanData;
}

const INITIAL_PLAN_STATE: PlanState = {
  planId: '',
  plans: [],
  isDirty: false,
  updated: '',
  draft: {
    type: PlanDataTypeEnum.Leave, // Required to create a plan (API)
    version: settings.planVersion, // Required to create a plan (API)
    completed: false, // Required to create a plan (API)
    completionAcknowledged: false, // Required to create a plan (API)
  },
};

export interface ProgressState {
  surveyCompleted: boolean;
  planCompleted: boolean;
  planStep: number;
}

const INITIAL_PROGRESS_STATE: ProgressState = {
  surveyCompleted: false,
  planCompleted: false,
  planStep: 1,
};

interface NavigationState {
  backLink: string;
}

const INITIAL_NAVIGATION_STATE: NavigationState = Object.freeze({
  backLink: '',
});

export type StoreState = {
  navigation: NavigationState;
  progress: ProgressState;
  getProgress: () => ProgressState;
  survey: SurveyState;
  updateSurveySpec: (spec: SurveySpec) => void;
  clearSurveyData: () => void;
  plan: PlanState;
  getPlanDraftDirtyFlag: () => PlanState['isDirty'];
  touchPlan: () => void;
  setPlanId: (id: string) => void;
  setPlanName: (name: string) => void;
  getPlanId: () => PlanDataWithMetadata['id'];
  getPlanDraft: () => PlanData;
  clearPlanData: () => void;
  /** Global StoreState getter/setter (using immer/produce) */
  get: () => StoreState;
  set: (fn: (draft: StoreState) => void, name?: string) => void;
  clearStore: () => void;
};

export const useStore: UseStore<StoreState> = create<StoreState>(
  devtools(
    immer((set, get) => ({
      // NAVIGATION --------------------------------------------------------------------------------
      navigation: INITIAL_NAVIGATION_STATE,
      // PROGRESS ----------------------------------------------------------------------------------
      progress: INITIAL_PROGRESS_STATE,
      getProgress: () => get().progress,
      // SURVEY ------------------------------------------------------------------------------------
      survey: INITIAL_SURVEY_STATE,
      updateSurveySpec: (spec) =>
        set(({ survey }) => {
          survey.spec = spec;
        }, 'updateSurveySpec'),
      clearSurveyData: () =>
        set(({ survey, progress }) => {
          progress.surveyCompleted = false;
          survey.draft = { ...INITIAL_SURVEY_STATE.draft };
        }, 'clearSurveyData'),

      // PLAN --------------------------------------------------------------------------------------
      plan: { ...INITIAL_PLAN_STATE },
      touchPlan: () =>
        set(({ plan }) => {
          plan.updated = new Date().toISOString();
          plan.isDirty = true;
        }, 'touchPlan'),
      setPlanId: (id) =>
        set(({ plan }) => {
          plan.planId = id;
          localStorage.setItem(settings.planIdStorageKey, id);
        }, 'setPlanId'),
      setPlanName: (name) =>
        set(({ plan }) => {
          plan.draft.name = name;
        }, 'setPlanName'),
      getPlanId: () => get().plan.planId,
      getPlanDraftDirtyFlag: () => get().plan.isDirty,
      getPlanDraft: () => get().plan.draft,
      clearPlanData: () =>
        set(({ plan, progress }) => {
          progress.planCompleted = false;
          progress.planStep = 1;
          // Reset properties individually
          plan.draft = { ...INITIAL_PLAN_STATE.draft };
          plan.planId = INITIAL_PLAN_STATE.planId;
          plan.isDirty = INITIAL_PLAN_STATE.isDirty;
          plan.updated = INITIAL_PLAN_STATE.updated;
          plan.plans = INITIAL_PLAN_STATE.plans;
        }, 'clearPlanData'),

      // -------------------------------------------------------------------------------------
      get: () => get(),
      set: (fn: (draft: StoreState) => void, name) =>
        set(produce(fn), name ?? 'set'),
      clearStore: () => {
        set((draft) => {
          draft.plan = INITIAL_PLAN_STATE;
          draft.survey = INITIAL_SURVEY_STATE;
          draft.progress = INITIAL_PROGRESS_STATE;
        }, 'clearStore');
      },
    })),
    'DFES-Fire'
  )
);
