import { logger } from '@hatchd/utils';
import { isDev } from 'utils/helpers';
import {
  captureNonFatalError,
  onUserLoggedIn,
  onUserLoggedOut,
} from 'utils/sentry';
import { EventParams } from './types';

/**
 * NOTE:
 * Most of the methods and rules have been copied from Adapptors AnalyticsService.
 * Thanks Adapptor! :)
 */

// See https://firebase.google.com/docs/reference/cpp/group/event-names
const matchIllegalFirebaseName = /[^0-9a-zA-Z_]+/;
const replaceIllegalFirebaseName = /[^0-9a-zA-Z_]+/g;
// See https://support.google.com/firebase/answer/9237506?hl=en
// To find potential violations, try `grep -RE " = \"[^\"]{41,}\"" App/Services/Analytics shared/Services/AnalyticsService`
const MAXIMUM_EVENT_NAME_LENGTH = 40;
export const MAXIMUM_PARAMETER_COUNT = 25;
const MAXIMUM_PARAMETER_NAME_LENGTH = 40;
const MAXIMUM_PARAMETER_VALUE_LENGTH = 100;

/**
 * Describes a tag appended to an error.
 * Used for tracking the source of the error, eg API calls.
 */
export interface ErrorTag {
  tag?: string;
}

export default class AnalyticsService {
  public static onUserLoggedIn = async (name: string) => {
    const firebase = (await import('services/firebase/client')).default;
    if (!isDev()) {
      onUserLoggedIn(name);
    }
    firebase.analytics().setUserId(name);
    logger.log(`User ID set for analytics: ${name}`);
  };

  public static onUserLoggedOut = async () => {
    const firebase = (await import('services/firebase/client')).default;
    if (!isDev()) {
      onUserLoggedOut();
    }
    firebase.analytics().setUserId(''); // TODO: is this correct? Or how can we delete it.
    logger.log(`User ID cleared for analytics`);
  };

  /**
   * Create a Firebase `screen_view` event
   */
  public static trackScreen = async (name: string | null) => {
    const firebase = (await import('services/firebase/client')).default;
    try {
      logger.log('Log screen view analytics:', name);
      firebase.analytics().setCurrentScreen(name || '');
    } catch (error) {
      logger.log('Failed to log screen view analytics:', name);
      captureNonFatalError(error);
    }
  };

  /**
   * Log an analytics event or event ID with Sentry and Firebase Analytics
   */
  public static trackEvent = async (id: string, params: EventParams.Base) => {
    const firebase = (await import('services/firebase/client')).default;
    let name = id;
    const data: any = params;

    // Trim event name
    if (
      name.length > MAXIMUM_EVENT_NAME_LENGTH ||
      name.match(matchIllegalFirebaseName)
    ) {
      logger.warn('Analytics event name must be sanitized', name);
      name = name
        .slice(0, MAXIMUM_EVENT_NAME_LENGTH)
        .replace(replaceIllegalFirebaseName, '_');
    }

    // Trim event parameter names and values
    let parameterCount = 0;
    for (const key of Object.keys(data)) {
      parameterCount = parameterCount + 1;
      let value = data[key];

      if (
        key.length > MAXIMUM_PARAMETER_NAME_LENGTH ||
        key.match(matchIllegalFirebaseName)
      ) {
        logger.warn('Analytics event parameter name must be sanitized', {
          event: name,
          param: key,
        });
        delete data[key];
        data[
          key
            .slice(0, MAXIMUM_PARAMETER_NAME_LENGTH)
            .replace(replaceIllegalFirebaseName, '_')
        ] = value;
      }

      if (
        typeof value === 'string' &&
        value.length > MAXIMUM_PARAMETER_VALUE_LENGTH
      ) {
        // Just silently trim values.
        value = value.slice(0, MAXIMUM_PARAMETER_VALUE_LENGTH);
        data[key] = value;
      }
    }

    if (isDev()) {
      logger.log(`Analytics Event: ${name}`, data);

      if (parameterCount > MAXIMUM_PARAMETER_COUNT) {
        logger.error(
          `Event ${name} has ${parameterCount} parameters, greater than the allowed maximum ${MAXIMUM_PARAMETER_COUNT}`
        );
      }

      return;
    }

    try {
      logger.info('LogEvent:', name, data);
      firebase.analytics().logEvent(name, data);
    } catch (error) {
      // Using `id` instead of `name` to have the original value in the error.
      logger.log('Failed to log analytics event:', id, params);
      captureNonFatalError(error);
    }
  };
}
