import { Location, PlanData, PlanDataTypeEnum } from 'api/client';
import {
  MigrationFailedPayload,
  MigrationSuccessPayload,
} from 'api/migration/PlanMigrator';
import Firebase from 'services/firebase/client';
import AnalyticsService from './service';
import { EventNames, EventParams } from './types';

function getPlanTypeWithDefault(planType: PlanDataTypeEnum | undefined) {
  return planType || PlanDataTypeEnum.Leave;
}

/**
 * Get the common analytics parameters (`EventParams.Base`) for the current user and given plan.
 */
function getBaseEventParametersFromPlan(
  plan: PlanData | undefined
): EventParams.Base {
  // const firebase = import('services/firebase/client');
  const user = Firebase.auth().currentUser;

  return {
    uid: user?.uid,
    suburb: plan?.location?.suburb,
    postcode: plan?.location?.postcode,
    isAnonymous: !!user?.isAnonymous,
    isSignedUp: !!user && !user.isAnonymous,
    hasCompletePlan: !!plan?.completed,
  };
}

/**
 * Get the common analytics parameters (`EventParams.Base`) for the current user and given survey location.
 */
async function getBaseEventParametersFromSurvey(
  location: Location | undefined
) {
  const firebase = (await import('services/firebase/client')).default;
  const user = firebase.auth().currentUser;

  return {
    uid: user?.uid,
    suburb: location?.suburb,
    postcode: location?.postcode,
    isAnonymous: !!user?.isAnonymous,
    isSignedUp: !!user && !user.isAnonymous,
    hasCompletePlan: false,
  };
}

/**
 * ---
 * # ![](media://clipboard.png) Log when a survey is started (e.g., the "Let's go" button)
 * ---
 */
export async function logStartSurvey() {
  const params: EventParams.StartSurvey =
    await getBaseEventParametersFromSurvey(undefined);

  AnalyticsService.trackEvent(EventNames.startSurvey, params);
}

/**
 * ---
 * # ![](media://clipboard.png) Log when a survey question is viewed (or receives focus)
 * ---
 */
export async function logViewSurveyQuestion(
  id: string,
  location: Location | undefined
) {
  const params: EventParams.ViewSurveyQuestion = {
    ...(await getBaseEventParametersFromSurvey(location)),
    id,
  };

  AnalyticsService.trackEvent(EventNames.viewSurveyQuestion, params);
}

/**
 * ---
 * # ![](media://clipboard.png) Log when a survey is automatically exited due to an answer
 * ---
 */
export async function logExitSurvey(
  questionId: string,
  answerId: number,
  location: Location | undefined
) {
  const params: EventParams.ExitSurvey = {
    ...(await getBaseEventParametersFromSurvey(location)),
    questionId,
    answerId,
  };

  AnalyticsService.trackEvent(EventNames.exitSurvey, params);
}

/**
 * ---
 * # ![](media://clipboard.png) Log when a plan is created (doesn't include if the plan type is switched)
 * ---
 */
export async function logCreatePlan(
  planType: PlanDataTypeEnum,
  plan: PlanData | undefined
) {
  const params: EventParams.CreatePlan = {
    ...(await getBaseEventParametersFromPlan(plan)),
    planType: getPlanTypeWithDefault(planType),
  };

  AnalyticsService.trackEvent(EventNames.createPlan, params);
}

/**
 * ---
 * # ![](media://clipboard.png) Log when a plan step is viewed
 * ---
 */
export async function logViewPlanStep(
  step: number,
  id: EventParams.PlanStepId,
  plan: PlanData | undefined
) {
  const params: EventParams.ViewPlanStep = {
    ...(await getBaseEventParametersFromPlan(plan)),
    planType: getPlanTypeWithDefault(plan?.type),
    step,
    id,
  };

  AnalyticsService.trackEvent(EventNames.viewPlanStep, params);
}

/**
 * ---
 * # ![](media://clipboard.png) Log when a plan step moves from incomplete to complete
 * ---
 */
export async function logCompletePlanStep(
  step: number,
  id: EventParams.PlanStepId,
  planType: PlanDataTypeEnum,
  plan: PlanData | undefined
) {
  const params: EventParams.CompletePlanStep = {
    ...(await getBaseEventParametersFromPlan(plan)),
    planType: getPlanTypeWithDefault(planType),
    step,
    id,
  };

  AnalyticsService.trackEvent(EventNames.completePlanStep, params);
}

/**
 * ---
 * # ![](media://clipboard.png) Log when a plan is completed
 * ---
 */
export async function logCompletePlan(plan: PlanData | undefined) {
  const params: EventParams.CompletePlan = {
    ...(await getBaseEventParametersFromPlan(plan)),
  };

  AnalyticsService.trackEvent(EventNames.completePlan, params);
}

export async function logDismissPlan(
  step: number,
  id: EventParams.PlanStepId,
  plan: PlanData | undefined
) {
  const params: EventParams.DismissPlan = {
    ...(await getBaseEventParametersFromPlan(plan)),
    planType: getPlanTypeWithDefault(plan?.type),
    step,
    id,
  };

  AnalyticsService.trackEvent(EventNames.dismissPlan, params);
}

/**
 * ---
 * # ![](media://clipboard.png) Log when the plan type is switched (currently only Stay & Defend -> Leave Early)
 * ---
 */
export async function logSwitchPlan(
  newPlanType: PlanDataTypeEnum,
  step: number,
  id: EventParams.PlanStepId,
  plan: PlanData | undefined
) {
  const params: EventParams.SwitchPlan = {
    ...(await getBaseEventParametersFromPlan(plan)),
    planType: getPlanTypeWithDefault(newPlanType),
    step,
    id,
  };

  AnalyticsService.trackEvent(EventNames.switchPlan, params);
}

/**
 * ---
 * # ![](media://clipboard.png) Log when the plan type is switched (currently only Stay & Defend -> Leave Early)
 * ---
 */
export async function logMigratePlan(
  newPlanType: PlanDataTypeEnum,
  step: number,
  id: EventParams.PlanStepId,
  plan: PlanData | undefined
) {
  const params: EventParams.SwitchPlan = {
    ...(await getBaseEventParametersFromPlan(plan)),
    planType: getPlanTypeWithDefault(newPlanType),
    step,
    id,
  };

  AnalyticsService.trackEvent(EventNames.switchPlan, params);
}

function unwrapMigrationPayloadForAnalytics(
  planMigration: MigrationSuccessPayload | MigrationFailedPayload
) {
  switch (planMigration.type) {
    case 'migrated':
      return {
        stepsToMigrate: planMigration.stepsToMigrate,
        toVersion: planMigration.plan.version,
      };
    case 'migration-failed':
      return {
        failedStep: planMigration.failedStep,
        toVersion: planMigration.plan.version,
      };
  }
}

/**
 * ---
 * # ![](media://clipboard.png) Log when a plan migration has been attempted {@link EventNames.planMigrated}
 * ---
 *
 * @param planMigration the payload that comes from a plan that ran through the migrator
 * @param fromVersion the version from which the plan has been migrated from
 */
export function logPlanMigration(
  planMigration: MigrationSuccessPayload | MigrationFailedPayload,
  fromVersion: number
) {
  const params: EventParams.PlanMigrated = {
    ...getBaseEventParametersFromPlan(planMigration.plan),
    fromVersion,
    migrationType: planMigration.type,
    ...unwrapMigrationPayloadForAnalytics(planMigration),
  };

  AnalyticsService.trackEvent(EventNames.planMigrated, params);
}

/**
 * ---
 * # ![](media://clipboard.png) Log when the user opens a modal dialog {@link EventNames.modalDialogOpened}
 * ---
 *
 * @param dialogType the type of modal dialog opened {@link EventParams.ModalDialogType}
 *
 */
export function logModalDialogOpened(
  plan: PlanData | undefined,
  dialogType: EventParams.ModalDialogType
) {
  const params: EventParams.OpenedModalDialog = {
    ...getBaseEventParametersFromPlan(plan),
    dialogType,
    isAnonymous: false,
    isSignedUp: false,
    hasCompletePlan: false,
  };

  AnalyticsService.trackEvent(EventNames.modalDialogOpened, params);
}

/**
 * ---
 * # ![](media://clipboard.png) Log when a plan is Shared (the only way to retrieve a plan PDF in the app)
 *
 * ## NOTE!
 * This method has been modified to pass in an intent and no longer matches what Adapptor has done.
 *
 * ---
 */
export async function logGetPlanPdf(
  plan: PlanData | undefined,
  intent: EventParams.PlanPdfIntent = 'share'
) {
  const params: EventParams.GetPlanPdf = {
    ...(await getBaseEventParametersFromPlan(plan)),
    intent,
  };

  AnalyticsService.trackEvent(EventNames.getPlanPdf, params);
}

/**
 * ---
 * # ![](media://clipboard.png) Log when a user successfully signs up
 * ---
 */
export async function logSignUp(hasPlan: boolean, plan: PlanData | undefined) {
  const params: EventParams.SignUp = {
    ...(await getBaseEventParametersFromPlan(plan)),
    hasPlan,
  };

  AnalyticsService.trackEvent(EventNames.signUp, params);
}

/**
 * ---
 * # ![](media://clipboard.png) Log when a user clicks on a link on the resources (checklist) plan step
 * ---
 */
export async function logDownloadResource(
  title: string,
  plan: PlanData | undefined
) {
  const params: EventParams.DownloadResource = {
    ...(await getBaseEventParametersFromPlan(plan)),
    title,
    tag:
      getPlanTypeWithDefault(plan?.type) === PlanDataTypeEnum.Leave
        ? 'leave'
        : 'stay',
  };

  AnalyticsService.trackEvent(EventNames.downloadResource, params);
}
