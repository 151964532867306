import { logger } from '@hatchd/utils';
import { PlanData } from 'api/client';
import { PLAN_STEPS } from 'components/plan-summary/plan-steps';
import produce from 'immer';
import { logCompletePlan, logCompletePlanStep } from 'services/analytics';
import { isPlanCompleted, isPlanStepDone } from 'utils/helpers';
import { getPlanStepId } from 'utils/plan-helpers';
import { PartialState, State, StateCreator, UseStore } from 'zustand';
import { ProgressState, StoreState } from './store';

// Pilfered from `zustand/middleware` as it hasn't been exported
type NamedSet<S extends State> = (
  partial: PartialState<S>,
  replace?: boolean,
  name?: string
) => void;

// Immer config deliberately flips name/replace args to not have to constantly define the replace param.
type ImmerConfig<T extends State> = StateCreator<
  T,
  (fn: (draft: T) => void, name?: string, replace?: boolean) => void
>;

/** immer middleware */
export const immer =
  <T extends State>(config: ImmerConfig<T>): StateCreator<T> =>
  (set: NamedSet<T>, get, api) =>
    config(
      (fn, name = 'action', replace = false) =>
        set(produce(fn) as (state: T) => T, replace, name),
      get,
      api
    );

export function observeDraftPlanState(Store: UseStore<StoreState>) {
  return Store.subscribe<[PlanData, ProgressState]>(
    ([PlanDraftState, ProgressState], [prevPlanDraftState]) => {
      const planType = PlanDraftState?.type;
      if (!planType || !PlanDraftState) return;

      if (
        !isPlanStepDone(ProgressState.planStep, prevPlanDraftState) &&
        isPlanStepDone(ProgressState.planStep, PlanDraftState)
      ) {
        // The current plan step moved from incomplete to complete
        // Note: this can happen multiple times if the user removes input
        logCompletePlanStep(
          ProgressState.planStep,
          getPlanStepId(ProgressState.planStep, planType),
          planType,
          PlanDraftState
        );
      }

      // Check if a given plan has been completed.
      const completed = isPlanCompleted(PlanDraftState, PLAN_STEPS[planType]);
      if (!!PlanDraftState?.completed !== completed && completed) {
        Store.setState((StoreState) =>
          produce(StoreState, ({ plan: { draft: draftPlan } }) => {
            draftPlan.completed = true;
            completed && logger.info('Plan completed');
            logCompletePlan(draftPlan);
          })
        );
      }
    },
    (selector) => [selector.plan.draft, selector.progress]
  );
}
