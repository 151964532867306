import { PlanDataTypeEnum } from 'api/client';
import { EventParams } from 'services/analytics';

/**
 * Get the analytics event ID for the given step (1-based) and plan type.
 * H/T Brett @ Adapptor
 */
export function getPlanStepId(step: number, planType: PlanDataTypeEnum) {
  switch (step) {
    case 1:
      return EventParams.PlanStepId.LOCATION;
    case 2:
      return EventParams.PlanStepId.HOUSEHOLD;
    case 3:
      return planType === PlanDataTypeEnum.Leave
        ? EventParams.PlanStepId.WHEN_TO_LEAVE
        : EventParams.PlanStepId.BEFORE_THE_FRONT;
    case 4:
      return planType === PlanDataTypeEnum.Leave
        ? EventParams.PlanStepId.WHERE_WILL_WE_GO
        : EventParams.PlanStepId.AT_THE_FRONT;
    case 5:
      return planType === PlanDataTypeEnum.Leave
        ? EventParams.PlanStepId.WHO_WILL_WE_CALL
        : EventParams.PlanStepId.AFTER_THE_FRONT;
    case 6:
      return planType === PlanDataTypeEnum.Leave
        ? EventParams.PlanStepId.WHAT_WILL_WE_TAKE
        : EventParams.PlanStepId.WHEN_TO_ACT;
    case 7:
      return EventParams.PlanStepId.BACKUP;
    case 8:
      return EventParams.PlanStepId.SHELTER;
    case 9:
      return EventParams.PlanStepId.RESOURCES;
    default:
      return EventParams.PlanStepId.UNKNOWN;
  }
}
